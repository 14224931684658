export const checkingEmail = (props) => {
  return (
    <svg
      width="68"
      height="67"
      viewBox="0 0 68 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.861328"
        width="66.67"
        height="66.67"
        rx="33.335"
        fill="#FF974A"
      />
      <path
        d="M39.1966 17.9038H29.1961C23.3933 17.9038 22.9458 23.1201 26.0787 25.9598L42.314 40.7136C45.4469 43.5532 44.9994 48.7695 39.1966 48.7695H29.1961C23.3933 48.7695 22.9458 43.5532 26.0787 40.7136L42.314 25.9598C45.4469 23.1201 44.9994 17.9038 39.1966 17.9038Z"
        stroke="white"
        strokeWidth="2.31493"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
