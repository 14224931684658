import { CTAContainer, CTATitle, CTAMain } from "./style";
import Icons from "../Icons/index";
import { useStoreData } from "@/redux/onboardingSlice";
import { useCallback } from "react";
import _ from "lodash"

const CTA = (props) => {
  const debounceCTAClick = useCallback(
    _.debounce(() => {

     props.onClick()
    }, 1500,  {leading: true, trailing: true}),
    [props?.inactive, props?.loading, ...(props.clickDependency || [])]
  );
  
  return (
    <CTAMain disclaimer={props.disclaimer} fixed={props.fixed}>
      <CTAContainer
        customStyle={props.customStyle}
        inactive={props.inactive}
        ghost={props.ghost}
        loading={props.loading}
        onClick={() => {(!props.inactive && !props.loading) ? debounceCTAClick() : null}}
      >
        {props.loading ? (
          <CTATitle loading={true} customStyle={props.customStyle}>
            {props.loadingMessage || "Loading .."}
          </CTATitle>
        ) : (
          <>
            <span className={props.loading ? "load" : "refresh"}>
              {props.leftIcon && <Icons name={props.leftIcon} color={props.iconColor} />}
            </span>
            <CTATitle loading={false} customStyle={props.customStyle}>
              {props.title}
            </CTATitle>
            {props.rightIcon && <Icons name={props.rightIcon} />}
          </>
        )}
      </CTAContainer>
      <span className="disclaimer">{props.disclaimer}</span>
    </CTAMain>
  );
};

export default CTA;
