import { Container } from "./style"

const CTAGroup = (props) => {
    return(
        <Container>
            {props.children}
        </Container>
    )
}

export default CTAGroup