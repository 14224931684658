export const toastCaution = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.1321 13.2694L12.7988 3.66943C12.0821 2.37777 11.0904 1.66943 9.99876 1.66943C8.9071 1.66943 7.91543 2.37777 7.19876 3.66943L1.86543 13.2694C1.19043 14.4944 1.11543 15.6694 1.65709 16.5944C2.19876 17.5194 3.26543 18.0278 4.66543 18.0278H15.3321C16.7321 18.0278 17.7988 17.5194 18.3404 16.5944C18.8821 15.6694 18.8071 14.4861 18.1321 13.2694Z"
        fill="#FF974A"
      />
      <path
        d="M10 12.2917C9.65833 12.2917 9.375 12.0083 9.375 11.6667V7.5C9.375 7.15833 9.65833 6.875 10 6.875C10.3417 6.875 10.625 7.15833 10.625 7.5V11.6667C10.625 12.0083 10.3417 12.2917 10 12.2917Z"
        fill="white"
      />
      <path
        d="M10.0003 15.0003C9.95033 15.0003 9.89199 14.992 9.83366 14.9836C9.78366 14.9753 9.73366 14.9586 9.68366 14.9336C9.63366 14.917 9.58366 14.892 9.53366 14.8586C9.49199 14.8253 9.45033 14.792 9.40866 14.7586C9.25866 14.6003 9.16699 14.3836 9.16699 14.167C9.16699 13.9503 9.25866 13.7336 9.40866 13.5753C9.45033 13.542 9.49199 13.5086 9.53366 13.4753C9.58366 13.442 9.63366 13.417 9.68366 13.4003C9.73366 13.3753 9.78366 13.3586 9.83366 13.3503C9.94199 13.3253 10.0587 13.3253 10.1587 13.3503C10.217 13.3586 10.267 13.3753 10.317 13.4003C10.367 13.417 10.417 13.442 10.467 13.4753C10.5087 13.5086 10.5503 13.542 10.592 13.5753C10.742 13.7336 10.8337 13.9503 10.8337 14.167C10.8337 14.3836 10.742 14.6003 10.592 14.7586C10.5503 14.792 10.5087 14.8253 10.467 14.8586C10.417 14.892 10.367 14.917 10.317 14.9336C10.267 14.9586 10.217 14.9753 10.1587 14.9836C10.1087 14.992 10.0503 15.0003 10.0003 15.0003Z"
        fill="white"
      />
    </svg>
  );
};
