export const reloadSymbol= (props) => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M8.09206 4.23358C8.81706 4.01691 9.61706 3.87524 10.5004 3.87524C14.4921 3.87524 17.7254 7.10858 17.7254 11.1002C17.7254 15.0919 14.4921 18.3252 10.5004 18.3252C6.50872 18.3252 3.27539 15.0919 3.27539 11.1002C3.27539 9.61691 3.72539 8.23358 4.49206 7.08358"
        // stroke="black"
        stroke={props.color?props.color:"white"}
        

        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.05859 4.43341L9.46693 1.66675"
        // stroke="black"
        stroke={props.color?props.color:"white"}

        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.05859 4.43359L9.86693 6.48359"
        // stroke="black"
        stroke={props.color?props.color:"white"}

        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
