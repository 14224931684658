export const toastFail = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.5003 18.611C15.0837 18.611 18.8337 14.861 18.8337 10.2777C18.8337 5.69434 15.0837 1.94434 10.5003 1.94434C5.91699 1.94434 2.16699 5.69434 2.16699 10.2777C2.16699 14.861 5.91699 18.611 10.5003 18.611Z"
        fill="#FD4F4F"
      />
      <path
        d="M8.1416 12.6361L12.8583 7.91943"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.8583 12.6361L8.1416 7.91943"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
