export default function maskName(name) {
  if (name) {
    const names = name.split(" ");
    let maskedNames = names.map((e) => {
      if (e.length > 2) {
        return e[0] + e[1] + "*".repeat(e.length - 2);
      } else {
        return e;
      }
    });
    maskedNames = maskedNames.join(" ");
    return maskedNames;
  }
}
