import {
  SubmitPageContainer,
  IconContainer,
  SubmitTitle,
  SubmitSubtitle,
} from "./style";
import Icons from "../Icons";
import { setPageError, useStoreData } from "@/redux/onboardingSlice";
import CTA from "../CTA";
import CTAGroup from "../CTAGroup";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { digioPreESignThunk } from "@/redux/onboardingThunks";
import { FailPageDetails } from "@/constant/intermediatePageConstants";
import { trackEvent } from "../../util/mixpanel";
import { mixpanelProperties } from "@/constant/mixpanelProperties";
import { pageMapping } from "@/constant/pageMapping";
export default function StageFailed(props) {
  const { pageError, currentPage, bankVerificationLimitExceeded } =
    useStoreData();
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    if (pageError == "esign") {
      dispatch(digioPreESignThunk({}));
    }
  }, [pageError]);
  const renderTitle = () => {
    return (
      <>
        <SubmitTitle>{FailPageDetails[pageError].title}</SubmitTitle>
        {FailPageDetails[pageError].description && (
          <SubmitSubtitle>
            {FailPageDetails[pageError].description}
          </SubmitSubtitle>
        )}
      </>
    );
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", (url) => {
      dispatch(setPageError(null));
    });

    return () => router.events.off("routeChangeComplete", (url) => {
      dispatch(setPageError(null));
    })
  }, [])

  const tryAgainHandler = () => {
    if (FailPageDetails[pageError].ctaEvents) {
      if (FailPageDetails[pageError]?.ctaEventProps) {
        trackEvent({
          eventName: FailPageDetails[pageError]?.ctaEvents?.[0],
          eventDetails: {
            ...FailPageDetails[pageError]?.ctaEventProps,
          },
        });
      } else {
        trackEvent({
          eventName: FailPageDetails[pageError]?.ctaEvents?.[0],
          eventDetails: {},
        });
      }
    }
    router.push(FailPageDetails[pageError].tryAgainPage);
  };

  const continueHandler = () => {
    router.push(FailPageDetails[pageError].nextPage);    
  };

  const renderCTA = () => {
    if (FailPageDetails[pageError].type == "bankDetails") {
      if (bankVerificationLimitExceeded) {
        return (
          <CTA
            customStyle={{
              gap: "4px",
              padding: "16px 16px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              borderRadius: "12px",
              background: "#4266C8",
              height: "52px",
              width: "100%",
            }}
            fixed
            onClick={() => continueHandler()}
            title={"Upload Bank Proof"}
          />
        );
      } else {
        return (
          <CTAGroup>
            <CTA
              customStyle={{
                gap: "4px",
                padding: "16px 16px",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                borderRadius: "12px",
                background: "#4266C8",
                height: "52px",
                width: "100%",
                color: "#4266C8",
              }}
              ghost
              onClick={() => tryAgainHandler()}
              title={FailPageDetails[pageError].ctaTitle[0]}
            />
            <CTA
              customStyle={{
                gap: "4px",
                padding: "16px 16px",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                borderRadius: "12px",
                background: "#4266C8",
                height: "52px",
                width: "100%",
              }}
              onClick={() => continueHandler()}
              title={FailPageDetails[pageError].ctaTitle[1]}
            />
          </CTAGroup>
        );
      }
    }
    if (FailPageDetails[pageError].ctaTitle?.length > 1) {
      return (
        <CTAGroup>
          <CTA
            customStyle={{
              gap: "4px",
              padding: "16px 16px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              borderRadius: "12px",
              background: "#4266C8",
              height: "52px",
              width: "100%",
              color: "#4266C8",
            }}
            ghost
            onClick={() => tryAgainHandler()}
            title={FailPageDetails[pageError].ctaTitle[0]}
          />
          <CTA
            customStyle={{
              gap: "4px",
              padding: "16px 16px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              borderRadius: "12px",
              background: "#4266C8",
              height: "52px",
              width: "100%",
            }}
            onClick={() => continueHandler()}
            title={FailPageDetails[pageError].ctaTitle[1]}
          />
        </CTAGroup>
      );
    } else {
      return (
        <CTA
          customStyle={{
            gap: "4px",
            padding: "16px 16px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            background: "#4266C8",
            height: "52px",
            width: "100%",
          }}
          fixed
          onClick={() => tryAgainHandler()}
          title={FailPageDetails[pageError].ctaTitle[0]}
        />
      );
    }
  };
  return (
    <SubmitPageContainer>
      <IconContainer>
        <Icons name={"failedTick"} />
      </IconContainer>
      {renderTitle()}
      {renderCTA()}
    </SubmitPageContainer>
  );
}
