import {
  RiskTextContainer,
  RiskTextHeader,
  RiskList,
  RiskListItems,
} from "./style";

import CTA from "../CTA";
import {
  setShowModal,
  useStoreData,
  setCheckDDPI,
} from "@/redux/onboardingSlice";
import { useDispatch } from "react-redux";
import ModalDataInformation from "@/components/ModalDataInformation/index";

const SegementModalType = (props) => {
  const dispatch = useDispatch();

  const segmentData = {
    segmentLearnMore: {
      textHeader: "Why these segments are needed?",
      list: [
        "Derivatives - marketfeed strategies are Options strategies. So it is mandatory to have Futures and Options (FnO) segment activated",
        "DDPI - Demat Debit and Pledge Instruction (DDPI) is required for Trade Automation software to work",
      ],
    },
    riskDisclosures: {
      textHeader: "Risk Disclosures on Derivatives",
      list: [
        "9 out of 10 individual traders in equity Futures and Options Segment, incurred net losses",
        "On an average, loss makers registered net trading loss close to ₹50,000",
        "Over and above the net trading losses incurred. loss makers expended an additional 28% of net trading losses as transaction costs.",
        "Those making net trading profits, incurred between 15% to 50% of such profits as transaction cost",
      ],
    },
  };

  return (
    <>
      <RiskTextContainer>
        <RiskTextHeader>{segmentData[props.type].textHeader}</RiskTextHeader>
        <ModalDataInformation
          infoData={
            <span>
              This is as per SEBI Circular SEBI/HO/
              <br/>
              MIRSD/MIRSD-PoD-1/P/CIR/2023/73
            </span>
          }
        />
        <RiskList>
          {segmentData[props.type].list.map((list) => {
            return (
              <div key={list}>
                <RiskListItems>{list}</RiskListItems>
                <br />
              </div>
            );
          })}
        </RiskList>
        <CTA
          customStyle={{
            gap: "4px",
            padding: "16px 16px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            height: "52px",
            width: "100%",
            color: props.type == "segmentLearnMore" ? "#4266C8" : "#FFF",
          }}
          ghost={props.type == "segmentLearnMore"}
          title={props.ctaTitle}
          fixed={"bottom"}
          onClick={
            props.type == "segmentLearnMore"
              ? () => dispatch(setShowModal({ show: false, type: "" }))
              : props.type == "riskDisclosures"
              ? () => {
                  dispatch(setShowModal({ show: false, type: "" }));
                  dispatch(setCheckDDPI(true));
                }
              : " "
          }
        />
      </RiskTextContainer>
    </>
  );
};
export default SegementModalType;
