import {
  Container,
  EligibilityContainer,
  IiflContainer,
  IiflData,
  IconContainer,
  WhatsNextContainer,
} from "./style";
import Icons from "@/components/Icons";
import CTA from "@/components/CTA";
import CTAGroup from "@/components/CTAGroup";
import { setEligibility, setShowEligibility } from "@/redux/onboardingSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStoreData } from "@/redux/onboardingSlice";
import { useRouter } from "next/router";
import { supportHandlerBrowser } from "@/util/supportHandlerBrowser";
import { PAGES } from "@/constant/misc";
import maskName  from "../../util/maskName";
import { trackEvent } from "@/util/mixpanel";
import { mixpanelEvents } from "@/constant/mixpanelEvents";
import { mixpanelProperties } from "@/constant/mixpanelProperties";

function EligibilityCheck({ eligibilityCheckData, clientCode } = props) {
  const { showModal, eligibility, currentPage, clientName } = useStoreData();
  let frontIcon = eligibilityCheckData[eligibility]?.iconFront;
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const element = document.body;
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "end",
    });
  }, []);
  function pageNext(cta2) {
    switch (eligibility) {
      case "ELIGIBLE_USER": {
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_CLICK_CHECK_ACTIVATIONS,
          eventDetails: {
            [mixpanelProperties.CLIENT_ID]: clientCode,
            [mixpanelProperties.CLIENT_NAME]: clientName
          }
        })
        router.push(PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS);
        break;
      }
      case "ACTIVATED": {
        if (cta2) {
          supportHandlerBrowser();
          break;
        } else {
          dispatch(setEligibility(null));
          dispatch(setShowEligibility(false));
          break;
        }
      }
      case "UNMAPPED": {
        if (cta2) {
          trackEvent({
            eventName: mixpanelEvents.MIXPANEL_CLICK_HOW_TO_REMAP,
          })
          router.push(PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE);
          break;
        } else {
          supportHandlerBrowser();
          break;
        }
      }
      case "INELIGIBLE_USER": {
        if (cta2) {
          dispatch(setEligibility(null));
          dispatch(setShowEligibility(false));
          break;
        } else {
          supportHandlerBrowser();
          break;
        }
      }
      case "PENDING": {
        dispatch(setEligibility(null));
        dispatch(setShowEligibility(false));
        break;
      }
    }
  }

  return (
    <>
      <Container>
        <EligibilityContainer>
          {eligibilityCheckData?.[eligibility]?.whatsNext ? (
            <IconContainer>
              <Icons name={eligibilityCheckData[eligibility]?.icon} />
            </IconContainer>
          ) : (
            <Icons name={eligibilityCheckData?.[eligibility]?.icon} />
          )}
          <h1>{eligibilityCheckData?.[eligibility]?.title}</h1>
          <p>{eligibilityCheckData?.[eligibility]?.context}</p>
        </EligibilityContainer>
        {eligibilityCheckData?.[eligibility]?.iifl && (
          <IiflContainer id="box">
            <Icons name={"iiflLogoWords"} />
            <span className="divider"></span>
            <IiflData>
              <h3>{maskName(clientName)}</h3>
              <h5>Client ID:{clientCode}</h5>
            </IiflData>
          </IiflContainer>
        )}
        <CTAGroup>
          {eligibilityCheckData?.[eligibility]?.cta.cta2 && (
            <CTA
              onClick={() => {
                pageNext(true);
              }}
              customStyle={{
                gap: "4px",
                padding: "16px 16px",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "20px",
                letterSpacing: "0.1px",
                borderRadius: "12px",
                background: "#4266C8",
                height: "52px",
                width: "100%",
                color: "#4266C8",
              }}
              ghost
              title={eligibilityCheckData?.[eligibility]?.cta.cta2?.text}
              rightIcon={
                !frontIcon &&
                eligibilityCheckData?.[eligibility]?.cta.cta2?.icon
              }
              leftIcon={
                frontIcon && eligibilityCheckData?.[eligibility]?.cta.cta2?.icon
              }
              iconColor={eligibilityCheckData?.[eligibility]?.iconColor}
            />
          )}
          {eligibilityCheckData?.[eligibility]?.whatsNext && (
            <WhatsNextContainer>
              <h4>What’s next?</h4>
              <div className="authoriseContainer">
                <h2>Authorise Trades</h2>
                <p>Complete the Authorisation once your account is active</p>
              </div>
            </WhatsNextContainer>
          )}

          <CTA
            onClick={() => {
              pageNext();
            }}
            customStyle={{
              gap: "4px",
              padding: "16px 16px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              borderRadius: "12px",
              background: "#4266C8",
              height: "52px",
              width: "100%",
            }}
            title={eligibilityCheckData?.[eligibility]?.cta.cta1?.text}
            rightIcon={
              !frontIcon && eligibilityCheckData?.[eligibility]?.cta.cta1?.icon
            }
            leftIcon={
              frontIcon && eligibilityCheckData?.[eligibility]?.cta.cta1?.icon
            }
            iconColor={eligibilityCheckData?.[eligibility]?.iconColor}
          />
        </CTAGroup>
      </Container>
    </>
  );
}

export default EligibilityCheck;
