export const infoIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99992 18.3335C14.5916 18.3335 18.3333 14.5918 18.3333 10.0002C18.3333 5.4085 14.5916 1.66683 9.99992 1.66683C5.40825 1.66683 1.66659 5.40849 1.66659 10.0002C1.66659 14.5918 5.40825 18.3335 9.99992 18.3335ZM10.6249 13.3335C10.6249 13.6752 10.3416 13.9585 9.99992 13.9585C9.65825 13.9585 9.37492 13.6752 9.37492 13.3335L9.37492 9.16683C9.37492 8.82516 9.65825 8.54183 9.99992 8.54183C10.3416 8.54183 10.6249 8.82516 10.6249 9.16683L10.6249 13.3335ZM9.23325 6.35016C9.27492 6.24183 9.33325 6.1585 9.40825 6.07516C9.49159 6.00016 9.58325 5.94183 9.68325 5.90016C9.78325 5.8585 9.89159 5.8335 9.99992 5.8335C10.1083 5.8335 10.2166 5.8585 10.3166 5.90016C10.4166 5.94183 10.5083 6.00016 10.5916 6.07516C10.6666 6.1585 10.7249 6.24183 10.7666 6.35016C10.8083 6.45016 10.8333 6.5585 10.8333 6.66683C10.8333 6.77516 10.8083 6.8835 10.7666 6.9835C10.7249 7.0835 10.6666 7.17516 10.5916 7.2585C10.5083 7.3335 10.4166 7.39183 10.3166 7.4335C10.1166 7.51683 9.88325 7.51683 9.68325 7.4335C9.58325 7.39183 9.49159 7.3335 9.40825 7.2585C9.33325 7.17516 9.27492 7.0835 9.23325 6.9835C9.19159 6.8835 9.16659 6.77516 9.16659 6.66683C9.16659 6.5585 9.19159 6.45016 9.23325 6.35016Z"
        fill="#4266C8"
      />
    </svg>
  );
};
