import { useEffect } from "react"
import { auth } from "../../services/firebase/fire"
import { useDispatch } from "react-redux"
import { setLoggedIn, useStoreData } from "../../redux/onboardingSlice";

const FirebaseAuthChange = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if(user){
            dispatch(setLoggedIn(true))
          }
        })
        return () => unsubscribe
    },[])


    return(
        <>{props.children}</>
    )
}

export default FirebaseAuthChange