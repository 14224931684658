import styled from "styled-components";

export const RiskTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 36px 86px 16px;
  gap: 16px;
`;

export const RiskTextHeader = styled.h2`
  all: unset;
  color: #171725;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
export const RiskList = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 15px;
  /* gap: */
`;

export const RiskListItems = styled.li`
  color: #171725;
  font-feature-settings: "clig" off, "liga" off;

  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  letter-spacing: -0.1px;
`;
