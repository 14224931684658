import Fuse from "fuse.js";
import { INITIAL_STATE } from "./onboardingInitialStateTypes";

export const onboardingReducers = {
  setStage: (state: INITIAL_STATE, action: { payload: { stage: string } }) => {
    state.stage = action.payload.stage;
  },
  setShowModal: (state: INITIAL_STATE, action: any) => {
    (state.showModal = action.payload.show),
      (state.modalType = action.payload.type);
  },
  setOtpSent: (state: INITIAL_STATE, action: any) => {
    state.otpSent = action.payload;
  },
  setDisplaySearch: (state: INITIAL_STATE, action: any) => {
    (state.displaySearch = action.payload.bool),
      (state.searchType = action.payload.type);
  },
  setIntermediatePage: (state: INITIAL_STATE, action: any) => {
    state.intermediatePage = action.payload;
  },
  searchBankName: (state: INITIAL_STATE, action: any) => {
    const bankList = state.bankList;

    const fuseOptions = {
      // isCaseSensitive: false,
      // includeScore: false,
      shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: 2,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["value"],
    };
    if (action.payload.length == 0) {
      state.searchBankList = bankList;
    } else {
      const fuse = new Fuse(bankList, fuseOptions);

      const searchPattern = action.payload;

      const newBankList = fuse.search(searchPattern);

      let newBankListItems = newBankList.map((i) => {
        return i.item;
      });
      state.searchBankList = newBankListItems;
    }
  },
  setSearchBankList: (state: INITIAL_STATE, action: any) => {
    state.searchBankList = action.payload;
  },
  setBranchList: (state: INITIAL_STATE, action: any) => {
    state.branchIfscList = action.payload;
  },
  setProofCode: (state: INITIAL_STATE, action: any) => {
    state.proofCode = action.payload;
  },
  setSignatureMethod: (state: INITIAL_STATE, action: any) => {
    state.signatureMethod = action.payload;
  },
  setUploadType: (state: INITIAL_STATE, action: any) => {
    state.uploadType = action.payload;
  },
  setCheckDDPI: (state: INITIAL_STATE, action: any) => {
    state.segmentPageCheckBox = {
      ...state.segmentPageCheckBox,
      checkDDPI: action.payload,
    };
  },
  setCheckDerivatives: (state: INITIAL_STATE, action: any) => {
    state.segmentPageCheckBox = {
      ...state.segmentPageCheckBox,
      checkDerivatives: action.payload,
    };
  },
  setLoggedIn: (state: INITIAL_STATE, action: any) => {
    state.isLoggedIn = action.payload;
  },
  setBankName: (state: INITIAL_STATE, action: any) => {
    state.bankName = action.payload;
    state.branchIfscList = [];
  },
  setPageLoading: (state: INITIAL_STATE, action: any) => {
    state.pageLoading = action.payload;
  },
  setPageError: (state: INITIAL_STATE, action: any) => {
    state.pageError = action.payload;
  },
  setPageSuccess: (state: INITIAL_STATE, action: any) => {
    state.pageSuccess = action.payload;
  },
  setPageUnavailable: (state: INITIAL_STATE, action: any) => {
    state.pageUnavailable = action.payload;
  },
  pushToast: (state: INITIAL_STATE, action: any) => {
    state.toast = action.payload;
  },
  setNextPage: (state: INITIAL_STATE, action: any) => {
    state.nextPage = action.payload;
  },
  setCurrentPage: (state: INITIAL_STATE, action: any) => {
    state.currentPage = action.payload;
  },
  setAddressProofFiles: (state: INITIAL_STATE, action: any) => {
    state.addressProofFiles = action.payload;
  },
  setClientCode: (state: INITIAL_STATE, action: any) => {
    state.clientCode = action.payload;
  },
  setEmailAndMobile: (state: INITIAL_STATE, action: any) => {
    if (action.payload) {
      state.mobile = action.payload.mobile;
      state.email = action.payload.email;
    } else {
      state.email = null;
      state.mobile = null;
    }
  },
  setIsPreviewingDocument: (state: INITIAL_STATE, action: any) => {
    state.isPreviewingDocument = action.payload;
  },
  setKeyboardOpen: (state: INITIAL_STATE, action: any) => {
    state.keyboardResize = action.payload;
  },
  setMarketfeedDetails: (state: INITIAL_STATE, action: any) => {
    state.marketfeedDetails = action.payload;
  },
  setOtpVerified: (state: INITIAL_STATE, action: any) => {
    state.otpVerified = action.payload;
  },
  setconsentType: (state: INITIAL_STATE, action: any) => {
    state.consentType = action.payload;
  },
  setEmailAndMobileConsentType: (state: INITIAL_STATE, action: any) => {
    state.emailConsent = action.payload.emailConsent;
    state.mobileConsent = action.payload.mobileConsent;
  },
  setApiError: (state: INITIAL_STATE, action: any) => {
    state.apiError = action.payload;
  },
  setInitialLoading: (state: INITIAL_STATE, action: any) => {
    state.initialLoading = action.payload;
  },
  setDob: (state: INITIAL_STATE, action: any) => {
    state.dob = action.payload;
  },
  setUnavailableTryAgain: (state: INITIAL_STATE, action: any) => {
    state.unavailableTryAgain = action.payload;
  },
  setApiLoading: (state: INITIAL_STATE, action: any) => {
    state.apiLoading = action.payload;
  },
  setEligibility: (state: INITIAL_STATE, action: any) => {
    state.eligibility = action.payload;
  },
  setShowEligibility: (state: INITIAL_STATE, action: any) => {
    state.showEligibility = action.payload;
  },
  setFnoActivated: (state: INITIAL_STATE, action: any) => {
    state.fnoActivated = action.payload;
  },
  setDdpiActivated: (state: INITIAL_STATE, action: any) => {
    state.ddpiActivated = action.payload;
  },
  setClientName: (state: INITIAL_STATE, action: any) => {
    state.clientName = action.payload;
  },
};
