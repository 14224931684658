import { WhatsNextContainer, TitleContainer, ContentContainer } from "./style.js";

const WhatsNext = (props) => {
  return (
    <>
      <WhatsNextContainer>
        <TitleContainer>
          <h3>What’s next?</h3>
        </TitleContainer>
        <ContentContainer>
          <h4>{props.title}</h4>
          <br />
          <p>{props.description}</p>
        </ContentContainer>
      </WhatsNextContainer>
    </>
  );
};

export default WhatsNext;