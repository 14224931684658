export const supportWhatsapp = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M7.99825 2.00195C4.7313 2.00297 2.00185 4.73593 2.00187 8.00018C2.00187 9.17702 2.35213 10.3395 3.00586 11.3223L2 13.9984L5.36824 13.3893C6.19103 13.7915 7.09453 13.993 7.99825 13.9936C11.268 13.9958 14 11.2665 14 8.00018C14 4.73765 11.267 2.00002 7.99825 2.00195Z"
        stroke="#4266C8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.32793 10.6418C9.1394 11.1628 9.98543 10.7834 10.5746 10.3439C10.9816 10.0404 10.9681 9.45494 10.6205 9.08489L9.93506 8.35519C9.67505 8.61519 9.16839 8.74388 8.81635 8.63673C8.05966 8.40644 7.74208 8.01214 7.48471 7.61123C7.18913 7.15076 7.52292 6.46306 7.78293 6.20306L7.01893 5.50558C6.69684 5.21155 6.20761 5.16193 5.92658 5.49543C5.37055 6.15527 5.02474 7.15704 5.43428 7.7949C6.16947 8.93997 7.1828 9.90677 8.32793 10.6418Z"
        stroke="#4266C8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
