import { PAGES } from "./misc";

interface StageMapping {
   [key: string] : {
      page: string;
  }
}
export const stageMapping :StageMapping = {
    FORM_TWO_FILLED: {
        page: PAGES.PERSONAL_DETAILS
    },
    ONBOARDING_STARTED: {
        page: PAGES.PERSONAL_DETAILS
    },
    PERSONAL_DETAILS_VERIFIED: {
        page: PAGES.PERSONAL_ADDRESS
    },
    ADDRESS_PROOF_UPLOADED:{
        page: PAGES.UPLOAD_PROOF_V2_PAN_CARD
    },
    ADDRESS_PROOF_VERIFIED: {
        page: PAGES.BANK_DETAILS
    },
    PAN_CARD_VERIFIED: {
        page: PAGES.BANK_DETAILS
    },
    BANK_DETAILS_VERIFIED:{
        page: PAGES.SEGMENT_ACTIVATION
    },
    SEGMENT_FATCA_SELECTED:{
        page: PAGES.FINANCIAL_PROOF
    },
    FINANCIAL_PROOF_UPLOADED:{
        page: PAGES.UPLOAD_PROOF_V2_SELFIE
    },
    PHOTO_PROOF_UPLOADED:{
        page: PAGES.SIGNATURE_VERIFICATION_METHODS
    },
    SIGNATURE_UPLOADED:{
        page: PAGES.ESIGN
    }
}