import { styled } from "styled-components";

export const WhatsNextContainer = styled.div`
  padding: 16px ;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const TitleContainer = styled.div`
  text-align: start;

  h3 {
    all:unset;
    color: #4266c8;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi-Variable;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const ContentContainer = styled.div`
  padding: 16px;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid rgba(124, 127, 134, 0.2);
  background: #f2f3f7;

  h4 {
    all:unset;

    color: #171725;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi-Variable;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
  }
  p {
    all:unset;

    color: #171725;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi-Variable;
    font-size: 12px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
    letter-spacing: -0.1px;
  }
`;
