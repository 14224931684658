export const successTickBig = (props) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="81"
        height="81"
        viewBox="0 0 81 81"
        fill="none"
      >
        <path
          d="M40.5003 74.1803C58.8337 74.1803 73.8337 59.1803 73.8337 40.847C73.8337 22.5137 58.8337 7.51367 40.5003 7.51367C22.167 7.51367 7.16699 22.5137 7.16699 40.847C7.16699 59.1803 22.167 74.1803 40.5003 74.1803Z"
          stroke="#18B777"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.333 40.8464L35.7663 50.2798L54.6663 31.4131"
          stroke="#18B777"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
