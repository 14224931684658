export const sandsOfTime = () => {
  return (
    <>
      <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="66.67" height="66.67" rx="33.335" fill="#FF974A"/>
<path d="M38.8353 18.4036H28.8348C23.032 18.4036 22.5845 23.6199 25.7173 26.4595L41.9527 41.2133C45.0856 44.053 44.638 49.2693 38.8353 49.2693H28.8348C23.032 49.2693 22.5845 44.053 25.7173 41.2133L41.9527 26.4595C45.0856 23.6199 44.638 18.4036 38.8353 18.4036Z" stroke="white" stroke-width="2.31493" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

    </>
  );
};
