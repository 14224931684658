export const successTick = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M40.5013 73.3346C58.8346 73.3346 73.8346 58.3346 73.8346 40.0013C73.8346 21.668 58.8346 6.66797 40.5013 6.66797C22.168 6.66797 7.16797 21.668 7.16797 40.0013C7.16797 58.3346 22.168 73.3346 40.5013 73.3346Z"
        fill="#18B777"
      />
      <path
        d="M26.332 39.9997L35.7654 49.4331L54.6654 30.5664"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
