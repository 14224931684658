import store from "@/redux/onboardingStore";
import mixpanel from "mixpanel-browser";
import { pageMapping } from "../constant/pageMapping";
import { mixpanelProperties } from "@/constant/mixpanelProperties";
import { mixpanelEvents } from "@/constant/mixpanelEvents";
export const initializeMixPanel = async () => {
  const mixpanelToken: string = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "";
  mixpanel.init(mixpanelToken, {
    ignore_dnt: true,
    debug: true,
    verbose: true,
  });
};

export const initializeMixPanelUser = async (uid: string) => {
  mixpanel.identify(uid);
};

export const trackEvent = async ({
  eventName,
  eventDetails,
}: {
  eventName: string;
  eventDetails: { [index: string]: any };
}) => {
  let stage = store?.getState()?.onboarding.stage || "ONBOARDING LOGIN";
  let page = pageMapping[store.getState().onboarding.currentPage]?.pageName
  console.log("MIXPANEL EVENT", eventName, eventDetails, stage, page);

  mixpanel.track(eventName, {
    ...eventDetails,
    [mixpanelProperties.STAGE]: stage,
    [mixpanelProperties.PAGE]: page,
  });
};

export const startTrackTimedEvent = async () => {
  mixpanel.time_event(mixpanelEvents.MIXPANEL_VIEWED_USER_ONBOARDING_PAGE)
}

export const trackTimedEvent = async () => {
  let stage = store?.getState()?.onboarding.stage || "ONBOARDING LOGIN";
  let page = pageMapping[store?.getState()?.onboarding?.currentPage]?.pageName;
  let eventName = mixpanelEvents.MIXPANEL_VIEWED_USER_ONBOARDING_PAGE
  console.log(eventName, page, stage)
  mixpanel.track(eventName, {
    [mixpanelProperties.PAGE]: page,
    [mixpanelProperties.STAGE]: stage
  });
};



export const setMixpanelUserProperties = async ({
  properties,
}: {
  properties: { [index: string]: any };
}) => {
  console.log(properties)
  mixpanel.people.set({
    ...properties
  })
};
