const bankVerificationSubstrings = ['limit', 'clientcode']
export const checkBankVerificationLimit = (errString: string) => {
    let errStringLower = errString.toLowerCase();
    let flag = true
    bankVerificationSubstrings.forEach((item) => {
        let check = errStringLower.includes(item)
        if(!check){
            flag = false
        }
    })
    return flag
}