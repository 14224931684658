export const mixpanelEvents = {
  MIXPANEL_GET_ACCOUNT_OPENING_OTP: "get_account_opening_otp",
  MIXPANEL_ERROR_ACCOUNT_OPENING_OTP: "error_account_opening_otp",
  MIXPANEL_RESEND_ACCOUNT_OPENING_OTP: "resend_account_opening_otp",
  MIXPANEL_CLICK_VERIFY_ACCOUNT_OPENING_OTP: "click_verify_account_opening_otp",
  MIXPANEL_VIEW_ACCOUNT_OPENING_OTP_CONFIRMATION:
    "view_account_opening_otp_confirmation",
  MIXPANEL_CLICK_VERIFY_ACCOUNT_OPENING_EMAIL:
    "click_verify_account_opening_email",
  MIXPANEL_VERIFY_ACCOUNT_DETAILS: "verify_account_details",
  MIXPANEL_ERROR_ACCOUNT_DETAILS_VERIFICATON:
    "error_account_details_verificaton",
  MIXPANEL_RESEND_ACCOUNT_OPENING_EMAIL_LINK:
    "resend_account_opening_email_link",
  MIXPANEL_REFRESH_ACCOUNT_OPENING_EMAIL_VERIFICATION:
    "refresh_account_opening_email_verification",
  MIXPANEL_VIEW_ACCOUNT_DETAILS_SUBMITTING_SCREEN:
    "view_account_details_submitting_screen",
  MIXPANEL_VIEW_ACCOUNT_DETAILS_SUBMISSION: "view_account_details_submission",
  MIXPANEL_CLICK_TRY_DIFFERENT_ACCOUNT: "click_try_different_account",
  MIXPANEL_CLICK_FETCH_ADDRESS: "click_fetch_address",
  MIXPANEL_VIEW_FETCHING_ADDRESS: "view_fetching_address",
  MIXPANEL_VIEW_ADDRESS_FETCHING_CONFIRMATION:
    "view_address_fetching_confirmation",
  MIXPANEL_CLICK_ADD_ADDRESS_MANUALLY: "click_add_address_manually",
  MIXPANEL_CLICK_SELECT_ADDRESS_PROOF: "click_select_address_proof",
  MIXPANEL_CLICK_UPLOAD_ADDRESS_PROOF: "click_upload_address_proof",
  MIXPANEL_CLICK_SUBMIT_ADDRESS_PROOF: "click_submit_address_proof",
  MIXPANEL_CLICK_RETAKE_ADDRESS_PROOF: "click_retake_address_proof",
  MIXPANEL_CLICK_SUBMIT_ADDRESS: "click_submit_address",
  MIXPANEL_VIEW_SUBMITTING_ADDRESS: "view_submitting_address",
  MIXPANEL_VIEW_ADDRESS_SUBMISSION_CONFIRMATION:
    "view_address_submission_confirmation",
  MIXPANEL_CLICK_UPLOAD_PAN_PROOF: "click_upload_pan_proof",
  MIXPANEL_CLICK_SUBMIT_PAN_PROOF: "click_submit_pan_proof",
  MIXPANEL_CLICK_RETAKE_PAN_PROOF: "click_retake_pan_proof",
  MIXPANEL_VIEW_SUBMITTING_PAN: "view_submitting_pan",
  MIXPANEL_VIEW_PAN_SUBMISSION_CONFIRMATION: "view_pan_submission_confirmation",
  MIXPANEL_CLICK_SELECT_BANK_ACCOUNT: "click_select_bank_account",
  MIXPANEL_CLICK_VERIFY_BANK_ACCOUNT: "click_verify_bank_account",
  MIXPANEL_ERROR_VERIFY_BANK_ACCOUNT: "error_verify_bank_account",
  MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT: "view_verifying_bank_account",
  MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT_CONFIRMATION:
    "view_verifying_bank_account_confirmation",
  MIXPANEL_CLICK_RETRY_VERIFY_BANK_ACCOUNT: "click_retry_verify_bank_account",
  MIXPANEL_CLICK_UPLOAD_BANK_PROOF: "click_upload_bank_proof",
  MIXPANEL_CLICK_SUBMIT_BANK_PROOF: "click_submit_bank_proof",
  MIXPANEL_CLICK_RETAKE_BANK_PROOF: "click_retake_bank_proof",
  MIXPANEL_VIEW_SUBMITTING_BANK_PROOF: "view_submitting_bank_proof",
  MIXPANEL_VIEW_BANK_PROOF_SUBMISSION_CONFIRMATION:
    "view_bank_proof_submission_confirmation",
  MIXPANEL_CLICK_ACTIVATE_SEGMENTS: "click_activate_segments",
  MIXPANEL_VIEW_SEGMENT_ACTIVATION_DISCLAIMER:
    "view_segment_activation_disclaimer",
  MIXPANEL_CLICK_UPLOAD_FINANCIAL_BANK_PROOF:
    "click_upload_financial_bank_proof",
  MIXPANEL_CLICK_SUBMIT_FINANCIAL_BANK_PROOF:
    "click_submit_financial_bank_proof",
  MIXPANEL_CLICK_RETAKE_FINANCIAL_PROOF: "click_retake_financial_proof",
  MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF: "view_submitting_financial_proof",
  MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION:
    "view_financial_proof_submission_confirmation",
  MIXPANEL_CLICK_UPLOAD_PHOTO_PROOF: "click_upload_photo_proof",
  MIXPANEL_CLICK_SUBMIT_PHOTO_PROOF: "click_submit_photo_proof",
  MIXPANEL_CLICK_RETAKE_PHOTO_PROOF: "click_retake_photo_proof",
  MIXPANEL_VIEW_SUBMITTING_PHOTO_PROOF: "view_submitting_photo_proof",
  MIXPANEL_VIEW_PHOTO_SUBMISSION_CONFIRMATION:
    "view_photo_submission_confirmation",
  MIXPANEL_CLICK_UPLOAD_SIGNATURE: "click_upload_signature",
  MIXPANEL_CLICK_SUBMIT_SIGNATURE: "click_submit_signature",
  MIXPANEL_CLICK_RETAKE_SIGNATURE: "click_retake_signature",
  MIXPANEL_VIEW_SUBMITTING_SIGNATURE: "view_submitting_signature",
  MIXPANEL_VIEW_FINANCIAL_SIGNATURE_CONFIRMATION:
    "view_financial_signature_confirmation",
  MIXPANEL_VERIFY_KRA: "verify_kra",
  MIXPANEL_ERROR_KRA_VERIFICATON: "error_kra_verificaton",
  MIXPANEL_VIEW_KRA_SUBMISSION: "view_kra_submission",
  MIXPANEL_VIEWED_USER_ONBOARDING_PAGE: "viewed_user_onboarding_page",
  MIXPANEL_VIEW_USER_ONBOARDING_PAGE: "view_user_onboarding_page",
  MIXPANEL_CLICK_ESIGN_BROKER_TNC: "click_esign_broker_tnc",
  MIXPANEL_CLICK_RETRY_ESIGN_BROKER_TNC: "click_retry_esign_broker_tnc",
  MIXPANEL_VIEW_ESIGN_BROKER_CONFIRMATION: "view_esign_broker_confirmation",
  MIXPANEL_VIEW_USER_ONBOARDING_PAGE_ERROR: "view_user_onboarding_page_error",
  MIXPANEL_ERROR_SUBMIT_FINANCIAL_BANK_PROOF: "error_submit_financial_bank_proof",
  MIXPANEL_CLICK_CHECK_ELIGIBILITY :"click_check_eligibility",
  MIXPANEL_VIEW_ELIGIBILITY_CONFIRMATION :"view_eligibility_confirmation",
  MIXPANEL_CLICK_CHECK_ACTIVATIONS: "click_check_activations",
  MIXPANEL_CLICK_HOW_TO_REMAP: "click_how_to_remap",
  MIXPANEL_DOWNLOAD_REMAPPING_FORM: "download_remapping_form",
  MIXPANEL_VIEW_ELIGIBILITY_ACTIVATIONS: "view_eligibility_activations",
  MIXPANEL_CLICK_FNO_HELP: "click_fno_help",
  MIXPANEL_CLICK_DDPI_HELP: "click_ddpi_help",
  MIXPANEL_CLICK_TERMINAL_LOGIN: "click_terminal_login",
  MIXPANEL_CLICK_LINK_EXISTING_ACCOUNT: "click_link_existing_account",
  MIXPANEL_CLICK_AUTHENTICATE_ACCOUNT: "click_authenticate_account"
};
