import { useStoreData } from "../../redux/onboardingSlice";
import StageFailed from "../StageFailed";
import StageSubmitting from "../StageSubmitting";
import StageSuccess from "../StageSuccess";
import StageUnavailable from "../StageUnavailable";

import { IntermediateContainer } from "./style";
import { Loader } from "../Loader";

export default function IntermediatePage() {
  const {
    pageError,
    pageLoading,
    pageSuccess,
    initialLoading,
    pageUnavailable,
    apiLoading,
  } = useStoreData();
  const getIntermediatePage = () => {
    if (pageLoading) {
      return <StageSubmitting />;
    }
    if (pageError) {
      return <StageFailed />;
    }
    if (pageSuccess) {
      return <StageSuccess />;
    }
    if (pageUnavailable) {
      return <StageUnavailable />;
    }
  };
  if (initialLoading) {
    return <Loader />;
  }

  if(apiLoading){
    return <Loader />;
  }
  return (
    <>
      {(pageLoading || pageError || pageSuccess || pageUnavailable) && (
        <IntermediateContainer>{getIntermediatePage()}</IntermediateContainer>
      )}
    </>
  );
}
