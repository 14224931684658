import { configureStore } from "@reduxjs/toolkit";
import {onboardingSlice} from "./onboardingSlice";
import { analyticsMiddleware } from "./onboardingAnalyticsMiddleware";

const store = configureStore({
    reducer: {
        onboarding: onboardingSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false,
          }).concat(analyticsMiddleware)
    }
})

export default store