import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 36px;
  padding: 40px 60px 16px 60px;
  margin-bottom:30px;
  @media only screen and (min-width: 600px){
    margin-bottom:0px;
    padding: 0;
  }
`;
export const EligibilityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  line-height: normal;
  overflow: scroll;
  max-width: 375px;
  
  h1 {
    all: unset;
    text-align: center;
    color: #171725;
    font-size: 20px;
    font-weight: 700;
    margin-top: 12px;
  }
  p {
    all: unset;
    text-align: center;
    color: #707687;
    font-size: 14px;
    font-weight: 420;
    letter-spacing: -0.1px;
    margin-top: 4px;
    white-space: pre-line;
  }
`;
export const IiflContainer = styled.div`
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  align-items:center;
  justify-content:center
  gap: 20px;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid rgba(124, 127, 134, 0.2);
  background: #fff;
  .divider{
    all:unset;
    width:100%;
    content:" ";
    border-bottom:1px solid rgba(124, 127, 134, 0.20);
    padding-bottom:16px ;

  }
`;
export const IconContainer = styled.div`
  display: flex;
  width: 66.67px;
  height: 66.67px;
  padding: 2px;
  marrgin: 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 370.389px;
  background: #ff974a;
`;
export const IiflData = styled.div`
  all: unset;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;

  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.1px;

  h3 {
    all: unset;
    font-size: 16px;
    font-weight: 700;
  }
  h5 {
    all: unset;
    font-size: 14px;
    font-weight: 420;
  }
`;
export const WhatsNextContainer = styled.div`
  all: unset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  font-feature-settings: "clig" off, "liga" off;
  font-style: normal;
  line-height: normal;
  h4 {
    all: unset;
    color: #4266c8;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .authoriseContainer {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(124, 127, 134, 0.2);
    background: #f2f3f7;
    color: #171725;

    h2 {
      all: unset;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.1px;
    }
    p {
      all: unset;
      font-size: 12px;
      font-weight: 420;
      letter-spacing: -0.1px;
      white-space: pre-line;
    }
  }
`;
