import { SubmitPageContainer, IconContainer, SubmitTitle } from "./style";
import Icons from "../Icons/index";

const SubmitVerifyPage = () => {
  //use iconContainer to change the background according the passed props
  return (
    <>
      <SubmitPageContainer>
        <IconContainer icon="loading">
          <Icons name={"loadingVerify"} />
        </IconContainer>
        <SubmitTitle>Uploading Doc</SubmitTitle>
      </SubmitPageContainer>


    <SubmitPageContainer>
        <IconContainer>
          <Icons name={"successTick"} />
        </IconContainer>
        <SubmitTitle>
          Financial Proof <br /> Submitted Successfully!{" "}
        </SubmitTitle>
      </SubmitPageContainer>

      
      <SubmitPageContainer>
        <IconContainer>
          <Icons name={"failedTick"} />
        </IconContainer>
        <SubmitTitle>
          Financial Proof <br /> Submission Failed!{" "}
        </SubmitTitle>
      </SubmitPageContainer> 
    </>
  );
};

export default SubmitVerifyPage;
