import { styled } from "styled-components";

export const TermsAndConditionContainer = styled.div`
  padding: 0px 16px;
  height: 83dvh;
  overflow-y: scroll;
`;

export const TermsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
`;

export const TermsContent = styled.div`
  margin: 16px 0px;
  text-align: start;
  color: #171725;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  letter-spacing: -0.1px;
  &.bolded {
    font-weight: 700;
  }
`;
export const TermsContentTitle = styled.h3`
  all: unset;

  color: #171725;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
`;
export const ConditionContentListLetters = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 24px;
  list-style-type: lower-latin;
  &.bulletNumbers {
    list-style-type: decimal;
  }
  &.bullet {
    list-style-type: disc;
  }
`;
export const ConditionContentListLettersItem = styled.li`
  &.newBullet {
    text-decoration: underline;
    margin-bottom: 20px;
  }
`;
