export const mixpanelProperties = {
    ACCOUNT_OPENING_MOBILE: "Account Opening Mobile",
    EMAIL: "Account Opening Email",
    PAGE: "Page",
    STAGE: "Stage",
    STATUS: "Status",
    ERROR: "Error",
    PAN_NUMBER: "PAN Number",
    DOB: "DOB",
    FATHER_NAME: "Father's Name",
    GENDER: "Gender",
    MARITAL_STATUS: "Marital Status",
    SELECTION: "Selection",
    UPLOAD_MODE: "Upload Mode",
    ACCOUNT_OPENING_ADDRESS: "Account Opening Address",
    ACCOUNT_OPENING_CITY: "Account Opening City",
    ACCOUNT_OPENING_STATE: "Account Opening State",
    ACCOUNT_OPENING_PINCODE: "Account Opening Pincode",
    DISCLAIMER_TEXT: "Disclaimer Text",
    ENABLE_DDPI: "Enable DDPI",
    RISK_DISCLOSURE_ACKNOWLEDGEMENT: "Risk Disclosure Acknowledgement",
    BROKER: "Broker",
    CLIENT_ID: "Client ID",
    CONTACT_NUMBER: "Contact Number",
    CLIENT_NAME: "Client Name",
    FNO_ACTIVATION_STATUS: "FnO Activation Status",
    DDPI_ACTIVATION_STATUS: "DDPI Activation Status"
}