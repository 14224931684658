import { PAGES } from "./misc";
import { mixpanelEvents } from "./mixpanelEvents";
import { mixpanelProperties } from "./mixpanelProperties";

const SuccessPageDetails = {
  "bank-details": {
    type: "bankDetails",
    title: (
      <>
        Bank Account Verified <br /> Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.SEGMENT_ACTIVATION,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "bank-details-proof": {
    type: "bankDetailsProof",
    title: (
      <>
        Bank Details Submitted <br /> Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.SEGMENT_ACTIVATION,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "income-proof": {
    type: "incomeProof",
    title: (
      <>
        Income Proof <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.UPLOAD_PROOF_V2_SELFIE,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "upload-selfie": {
    type: "uploadSelfie",
    title: (
      <>
        Photo <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.SIGNATURE_VERIFICATION_METHODS,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "upload-pan": {
    type: "uploadPan",
    title: (
      <>
        PAN <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.BANK_DETAILS,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "signature-upload": {
    type: "signatureUpload",
    title: (
      <>
        Signature <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.ESIGN,
    nextPageTitle: "eSign in Account Opening Application",
    nextPageDescription:
      "Use Aadhar number linked to the registered mobile number",
  },
  "address-proof": {
    type: "addressProof",
    title: (
      <>
        Personal Address <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.UPLOAD_PROOF_V2_PAN_CARD,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  "personal-details": {
    type: "personalDetails",
    title: (
      <>
        Personal Details <br /> Submitted Successfully!{" "}
      </>
    ),
    description: null,
    ctaTitle: "Next",
    nextPage: PAGES.PERSONAL_ADDRESS,
    nextPageTitle: null,
    nextPageDescription: null,
  },
  esign: {
    type: "esign",
    title: `eSign Successfull!`,
    description: `Your IIFL account opening is in progress. We will notify you once
        it is active. Usually it takes 1 to 2 days`,
    ctaTitle: "Got it",
    nextPage: "/",
    nextPageTitle: "Authorise Trades",
    nextPageDescription:
      "Complete the Authorisation once your account is active",
  },
};

const SubmittingPageDetails = {
  "bank-details": {
    type: "bankDetails",
    title: (
      <>
        Verifying the bank <br /> account, please wait..
      </>
    ),
  },
  "bank-details-proof": {
    type: "bankDetailsProof",
    title: (
      <>
        Submitting Your Bank
        <br /> Details, Please Wait..
      </>
    ),
  },
  "income-proof": {
    type: "incomeProof",
    title: (
      <>
        Uploading
        <br /> Your Document..
      </>
    ),
  },
  "upload-selfie": {
    type: "uploadSelfie",
    title: (
      <>
        Submitting Your Photo,
        <br /> Please wait..
      </>
    ),
  },
  "upload-pan": {
    type: "uploadPan",
    title: (
      <>
        Submitting Your PAN,
        <br /> Please wait..
      </>
    ),
  },
  "signature-upload": {
    type: "signatureUpload",
    title: <>Submitting Your Signature..</>,
  },
  "address-proof": {
    type: "addressProof",
    title: (
      <>
        Submitting Your Personal <br />
        Address, Please Wait..
      </>
    ),
  },
  "personal-details": {
    type: "personalDetails",
    title: (
      <>
        Submitting You Personal <br />
        Details, Please Wait..
      </>
    ),
  },
};

const FailPageDetails = {
  "bank-details": {
    type: "bankDetails",
    title: (
      <>
        Bank Account
        <br />
        Verification Failed
      </>
    ),
    description: (
      <>
        Please try again with the same or <br /> a different bank account
      </>
    ),
    ctaTitle: ["Try Again", "Continue Anyway"],
    ctaEvents: [mixpanelEvents.MIXPANEL_CLICK_RETRY_VERIFY_BANK_ACCOUNT],
    nextPage: PAGES.BANK_PROOF,
    tryAgainPage: PAGES.BANK_DETAILS,
  },
  "bank-details-proof": {
    type: "bankDetailsProof",
    title: (
      <>
        Bank Details
        <br />
        Submission Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    ctaEvents: [mixpanelEvents.MIXPANEL_CLICK_RETAKE_BANK_PROOF],
    nextPage: null,
    tryAgainPage: PAGES.BANK_PROOF,
  },
  "income-proof": {
    type: "incomeProof",
    title: (
      <>
        Income Proof <br /> Submission Failed!{" "}
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    nextPage: null,
    tryAgainPage: PAGES.FINANCIAL_PROOF,
  },
  "upload-selfie": {
    type: "uploadSelfie",
    title: (
      <>
        Photo Submission
        <br />
        Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    nextPage: null,
    tryAgainPage: PAGES.UPLOAD_PROOF_V2_SELFIE,
  },
  "upload-pan": {
    type: "uploadPan",
    title: (
      <>
        PAN Submission
        <br />
        Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    nextPage: null,
    tryAgainPage: PAGES.UPLOAD_PROOF_V2_PAN_CARD,
  },
  "signature-upload": {
    type: "signatureUpload",
    title: (
      <>
        Signature
        <br />
        Submission Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    nextPage: null,
    tryAgainPage: PAGES.SIGNATURE_VERIFICATION_METHODS,
  },
  "address-proof": {
    type: "addressProof",
    title: (
      <>
        Personal Address
        <br />
        Submission Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    nextPage: null,
    tryAgainPage: PAGES.PERSONAL_ADDRESS,
  },
  "personal-details": {
    type: "personalDetails",
    title: (
      <>
        Personal Details
        <br />
        Submission Failed!
      </>
    ),
    description: null,
    ctaTitle: ["Try Again"],
    ctaEvents: [mixpanelEvents.MIXPANEL_CLICK_TRY_DIFFERENT_ACCOUNT],
    nextPage: null,
    tryAgainPage: PAGES.PERSONAL_DETAILS,
  },
  "esign": {
    type: "esign",
    title: `eSign Failed!`,
    ctaTitle: ["Try Again"],
    ctaEvents: [mixpanelEvents.MIXPANEL_CLICK_RETRY_ESIGN_BROKER_TNC],
    ctaEventProps: {[mixpanelProperties.BROKER]: 'IIFL'},
    nextPage: null,
    tryAgainPage: PAGES.ESIGN,
  },
  "iifl-Oops! Something": {
    type: "iiflUnavailable",
    title: (
      <>
        IIFL Account Opening
        <br />
        Temporarily Unavailable
      </>
    ),
    description: (
      <>
        We're currently facing technical issues <br /> with opening IIFL
        account. Please try <br />
        again later
      </>
    ),
  },

};
const IIFlUnavailable = {
  "iifl-unvailable": {
    type: "iiflUnavailable",
    title: (
      <>
        IIFL Account Opening
        <br />
        Temporarily Unavailable
      </>
    ),
    description: (
      <>
        We're currently facing technical issues <br /> with opening IIFL
        account. Please try <br />
        again later
      </>
    ),
    ctaTitle: ["Got it"],

  }
}


export { SuccessPageDetails, SubmittingPageDetails, FailPageDetails,IIFlUnavailable};
