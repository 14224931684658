export const preferred = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.04554 6.64019H4.59054V10.2402C4.59054 11.0802 5.04554 11.2502 5.60054 10.6202L9.38554 6.32019C9.85054 5.79519 9.65554 5.36019 8.95054 5.36019H7.40554V1.76019C7.40554 0.920193 6.95054 0.750193 6.39554 1.38019L2.61054 5.68019C2.15054 6.21019 2.34554 6.64019 3.04554 6.64019Z"
        fill="#FF974A"
      />
    </svg>
  );
};
