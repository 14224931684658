import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 16px;
    gap: 24px;
`

export const TypeLine = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    .icon{
        width: 24px;
        display: flex;
        align-items: center;
    }
    .label{
        color: #171725;
        font-feature-settings: 'clig' off, 'liga' off;
        /* Headline Medium */
        font-family: Satoshi-Variable;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
    }
`