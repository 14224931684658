declare const window: Window &
  typeof globalThis & {
    ["useronboardingchannel"]: any;
  };
export const supportHandlerBrowser = () => {
  let supportMessage = "Hi, I need help with opening IIFL Account";

  const supportUri = `https://wa.me/send/?phone=${process.env.NEXT_PUBLIC_SUPPORT_NUMBER}&text=${supportMessage}&app_absent=0`;

  //need phone number without '+' for NEXT_PUBLIC_SUPPORT_NUMBER

  const encodedSupportUri = encodeURI(supportUri);

 window.open(encodedSupportUri, "_blank");

};
