import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { initialState } from "./onboardingInitialState";
import { onboardingReducers } from "./onboardingReducer";
import { onboardingThunkReducers } from "./onboardingThunkReducer";

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: onboardingReducers,
  extraReducers: onboardingThunkReducers,
});

export const {
  setStage,
  setShowModal,
  setDisplaySearch,
  setIntermediatePage,
  searchBankName,
  setProofCode,
  setSignatureMethod,
  setUploadType,
  setCheckDDPI,
  setCheckDerivatives,
  setLoggedIn,
  setBankName,
  setPageError,
  setPageLoading,
  setPageSuccess,
  setPageUnavailable,
  pushToast,
  setNextPage,
  setCurrentPage,
  setAddressProofFiles,
  setClientCode,
  setEmailAndMobile,
  setOtpSent,
  setIsPreviewingDocument,
  setKeyboardOpen,
  setSearchBankList,
  setBranchList,
  setMarketfeedDetails,
  setOtpVerified,
  setconsentType,
  setEmailAndMobileConsentType,
  setApiError,
  setInitialLoading,
  setDob,
  setUnavailableTryAgain,
  setApiLoading,
  setEligibility,
  setShowEligibility,
  setDdpiActivated,
  setFnoActivated,
  setClientName,
  
} = onboardingSlice.actions;

export const useStoreData = () => {
  return useSelector((state: any) => state.onboarding);
};

export default onboardingSlice.reducer;
