import styled from "styled-components";

export const IIFLUnavailableModalContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 60px;
  margin-bottom: 68px;
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
`;

export const TextTitle = styled.h1`
  all: unset;
  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const TextDescription = styled.h4`
  all: unset;
  color: #707687;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  letter-spacing: -0.1px;
`;
