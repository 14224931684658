import { Container, TypeLine } from "./style"
import Icons from "../Icons"
import { useDispatch } from "react-redux"
import { setUploadType } from "@/redux/onboardingSlice"
import { useEffect } from "react"

const UploadType = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setUploadType(""))
    })
    return(
        <Container>
            <TypeLine onClick={() => dispatch(setUploadType('takePhoto'))}>
                <span className="icon"><Icons name={"takePhoto"} /></span>
                <span className="label">Take Photo</span>
            </TypeLine>
            <TypeLine onClick={() => dispatch(setUploadType('uploadPhoto'))}>
                <span className="icon"><Icons name={"uploadPhoto"} /></span>
                <span className="label">Upload from Files</span>
            </TypeLine>
        </Container>
    )
}

export default UploadType