import { styled } from "styled-components";

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100vw;
    height: 60px;
    box-shadow: 0px 8px 8px 0px rgba(112, 118, 135, 0.04);
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index:10;
    @media only screen and (min-width: 600px){
        height: 80px;
    }
`

export const Padding = styled.div`
    width: 100vw;
    padding: 0 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 34px;
    box-shadow: 0px 8px 8px 0px rgba(112, 118, 135, 0.04);
    padding: 12px;
    @media only screen and (min-width: 600px){
        height: 80px;
        padding: 0 80px;
    }
`

export const Left = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    .leftIcon{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .title{
        font-family: Satoshi-Variable;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
        color: #000;
    }
    .icon{
        width: 24px;
    }
    @media only screen and (min-width: 600px){
        align-items: flex-start;
    }
`

export const Right = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
`

export const SearchHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    .icon{
        display: flex;
        align-items: center;
        width: 24px;
    }
`

export const HeaderSearch = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid rgba(124, 127, 134, 0.20);
    padding: 12px;
    width: 100%;
    .icon{
        display: flex;
        align-items: center;
        width: 20px;
    }
    input{
        all: unset;
        display: flex;
        align-items: center;
        width: 100%
    }
    input::placeholder{
        color: #707687;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Satoshi-Variable;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        letter-spacing: 0.1px;
    }
`

export const ProgressBar = styled.div`
    background-color: rgba(129, 152, 218, 0.20);
    width: 100vw;
    height: 2px;
    position: absolute;
    bottom: 0;

    .progress{
        transition: width 0.5s ease-in;
    }
`