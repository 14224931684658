import {
  pushToast,
  setPageSuccess,
  useStoreData,
} from "@/redux/onboardingSlice";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { SuccessPageDetails } from "../../constant/intermediatePageConstants";
import CTA from "../CTA";
import Icons from "../Icons";
import {
  IconContainer,
  SubmitDescription,
  SubmitPageContainer,
  SubmitTitle,
  CtaPlusContainer,
} from "./style";
import WhatsNext from "../../components/WhatsNext";
import { closeEvent } from "@/util/closeEvent";
import { PAGES } from "@/constant/misc";
import { useEffect } from "react";
export default function StageSuccess(props) {
  const { pageSuccess, digioRedirect, currentPage } = useStoreData();
  const router = useRouter();
  const dispatch = useDispatch();
  const renderTitle = () => {
    return (
      <>
        <SubmitTitle>{SuccessPageDetails[pageSuccess].title}</SubmitTitle>
        {SuccessPageDetails[pageSuccess].description && (
          <SubmitDescription>
            {SuccessPageDetails[pageSuccess].description}
          </SubmitDescription>
        )}
      </>
    );
  };

  useEffect(() => {
    router.events.on("routeChangeComplete", (url) => {
        dispatch(setPageSuccess(null))
    });

    () => {
      router.events.off("routeChangeComplete", (url) => {
        dispatch(setPageSuccess(null))
    })
    }
  }, [])

  const nextHandler = () => {
    if (SuccessPageDetails[pageSuccess].type == "esign") {
      closeEvent();
    }
    else if(SuccessPageDetails[pageSuccess].type == "addressProof"){
      if(currentPage == PAGES.PERSONAL_ADDRESS_DIGILOCKER){
        router.push(PAGES.BANK_DETAILS)
      }else{
        router.push(SuccessPageDetails[pageSuccess].nextPage);
      }
    }
    else {
      router.push(SuccessPageDetails[pageSuccess].nextPage);
    }    
  };

  const renderCTA = () => {
    return (
      <>
        <CTA
          customStyle={{
            gap: "4px",
            padding: "16px 16px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            background: "#4266C8",
            height: "52px",
            width: "100%",
          }}
          fixed
          onClick={() => nextHandler()}
          title={SuccessPageDetails[pageSuccess].ctaTitle}
        />
      </>
    );
  };
  return (
    <>
      <SubmitPageContainer>
        <IconContainer>
          <Icons name={"successTick"} />
        </IconContainer>
        {renderTitle()}
      </SubmitPageContainer>
      <CtaPlusContainer>
        {SuccessPageDetails[pageSuccess].nextPageTitle && (
          <WhatsNext
            title={SuccessPageDetails[pageSuccess].nextPageTitle}
            description={SuccessPageDetails[pageSuccess].nextPageDescription}
          />
        )}{" "}
        {renderCTA()}
      </CtaPlusContainer>
    </>
  );
}
