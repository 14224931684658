export const toastSuccess = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M10.7782 18.611C15.3805 18.611 19.1115 14.88 19.1115 10.2777C19.1115 5.6753 15.3805 1.94434 10.7782 1.94434C6.17578 1.94434 2.44482 5.6753 2.44482 10.2777C2.44482 14.88 6.17578 18.611 10.7782 18.611Z"
        fill="#18B777"
      />
      <path
        d="M9.59502 13.261C9.42835 13.261 9.27002 13.1943 9.15335 13.0777L6.79502 10.7193C6.55335 10.4777 6.55335 10.0777 6.79502 9.836C7.03669 9.59434 7.43669 9.59434 7.67835 9.836L9.59502 11.7527L13.8784 7.46934C14.12 7.22767 14.52 7.22767 14.7617 7.46934C15.0034 7.711 15.0034 8.111 14.7617 8.35267L10.0367 13.0777C9.92002 13.1943 9.76169 13.261 9.59502 13.261Z"
        fill="white"
      />
    </svg>
  );
};
