import { ModalDatacontainer, ModalInformationData } from "./style";
import Icons from "../Icons/index";

const ModalDataInformation = (props) => {
  return (
    <>
      <ModalDatacontainer>
        <Icons name="infoIcon" />
        <ModalInformationData>
            {props.infoData}
  
        </ModalInformationData>
      </ModalDatacontainer>
    </>
  );
};

export default ModalDataInformation;
