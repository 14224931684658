import { pushToast, useStoreData } from "../../redux/onboardingSlice";
import { ToastContainer, ToastMain } from "./style";
import Icons from "../Icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export default function Toast(props) {
  const { toast } = useStoreData();
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    if(toast){
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 2000)
    }
  }, [toast]);
  useEffect(() => {
    if(!showToast){
        dispatch(pushToast(null))
    }
  },[showToast])
  const getIconName = () => {
    switch (toast?.type) {
      case "caution":
        return "toastCaution";
      case "fail":
        return "toastFail";
      case "success":
        return "toastSuccess";
      default:
        return "toastFail"
    }
  };

  if(showToast){
    return (
        <>
          {showToast && (
            <ToastMain>
              <ToastContainer type={toast?.type}>
              <span className="icon">
                <Icons name={getIconName()} />
              </span>
              <span className="label" style={{ whiteSpace: 'pre-line' }}>{toast?.label}</span>
            </ToastContainer>
            </ToastMain>
            
          )}
        </>
      );
  }
}
