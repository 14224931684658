export const cancelBig = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="81"
      viewBox="0 0 81 81"
      fill="none"
    >
      <path
        d="M40.5003 73.8337C58.8337 73.8337 73.8337 58.8337 73.8337 40.5003C73.8337 22.167 58.8337 7.16699 40.5003 7.16699C22.167 7.16699 7.16699 22.167 7.16699 40.5003C7.16699 58.8337 22.167 73.8337 40.5003 73.8337Z"
        fill="#FD4F4F"
      />
      <path
        d="M31.0664 49.9331L49.9331 31.0664"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M49.9331 49.9331L31.0664 31.0664"
        stroke="white"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
