import styled from "styled-components";
export const IntermediateContainer = styled.div`
    display: flex;
    height: 100dvh;
    width: 100vw;
    background-color: #fff;
    z-index: 99;
    position: sticky;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`