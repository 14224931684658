import { styled } from "styled-components";

export const VerifyEmailComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    padding: 0 16px;
`

export const VerifyEmailContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px;
    gap: 12px;
    .top{
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        .title{
            color: #171725;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            /* H2 */
            font-family: Satoshi-Variable;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        .subtitle{
            color: #171725;
            text-align: center;
            font-feature-settings: 'clig' off, 'liga' off;
            /* Subtitle Regular */
            font-family: Satoshi-Variable;
            font-size: 14px;
            font-style: normal;
            font-weight: 420;
            line-height: normal;
            letter-spacing: -0.1px;
            .email{
                color: #171725;
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Satoshi-Variable;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.1px;
            }
        }
    }
    .bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 22px;
        overflow: hidden;
        position: relative;
        .resend{
            color: #4266C8;
            font-family: Satoshi-Variable;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.1px;
        }
        .resendIn{
            color: #171725;
            font-family: Satoshi-Variable;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.1px;
            .secondsTimer {
                color: #171725
                font-family: Satoshi-Variable;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.1px;
              }
        }

        .animateIn{
            position: absolute;
            @keyframes cardIn {
                0% {
                  top: -20px;
                }
                100% {
                  top: 0;
                }
            }
            animation: card 0.2s ease-in;
        }

        .animateOut{
            position: absolute;
            @keyframes cardOut {
                0% {
                  top: 20px;
                }
                100% {
                  top: 0;
                }
            }
            animation: card 0.2s ease-out;
        }


        

    }
`

export const VerifyBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 12px 0;
    width: 100%;
    .already{
        color: #171725;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        /* Subtitle Regular */
        font-family: Satoshi-Variable;
        font-size: 14px;
        font-style: normal;
        font-weight: 420;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    .bottomCTA{
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
    }
`