import { styled } from "styled-components";

export const CTAMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.disclaimer ? "12px" : 0)};
  align-items: center;
  position: ${(props) => (props.fixed ? "fixed" : null)};
  bottom: ${(props) => (props.fixed ? "0" : null)};
  right: ${(props) => (props.fixed ? "0" : null)};
  left: ${(props) => (props.fixed ? "0px" : null)};
  padding: ${(props) => (props.fixed ? "16px 16px" : 0)};
  background-color: #fff;
  .disclaimer {
    color: #707687;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi-Variable;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.1px;
  }
  .termsAndCondition {
    color: #4266c8;
  }
  @media only screen and (min-width: 600px){
    position: unset;
    padding: 0;
    width: 100%;
  }
`;

export const CTAContainer = styled.button`
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${(props) =>
    props.customStyle.padding ? props.customStyle.padding : 0};
  gap: ${(props) => (props.customStyle.gap ? props.customStyle.gap : 0)};
  background: ${(props) =>
    props.loading
      ? "rgba(129, 152, 218, 0.20)"
      : props.inactive
      ? "rgba(124, 127, 134, 0.20)"
      : props.ghost
      ? "transparent"
      : "#4266C8"};
  border: ${(props) =>
    (props.inactive && !props.ghost) || props.loading
      ? "transparent"
      : "1px solid #4266C8"};
  border-radius: ${(props) =>
    props.customStyle.borderRadius ? props.customStyle.borderRadius : 0};
  height: ${(props) =>
    props.customStyle.height ? props.customStyle.height : 0};
  width: ${(props) =>
    props.customStyle.width ? props.customStyle.width : null};
  box-sizing: border-box;
  .load {
    display: flex;
    align-items: center;
    justify-content: center;
    @-webkit-keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    -webkit-animation: rotating 2s linear infinite;
  }
  .refresh {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const CTATitle = styled.span`
  font-family: Satoshi-Variable;
  font-size: ${(props) =>
    props.customStyle.fontSize ? props.customStyle.fontSize : "12px"};
  font-weight: ${(props) =>
    props.customStyle.fontWeight ? props.customStyle.fontWeight : 500};
  line-height: ${(props) =>
    props.customStyle.lineHeight ? props.customStyle.lineHeight : "normal"};
  letter-spacing: ${(props) =>
    props.customStyle.letterSpacing
      ? props.customStyle.letterSpacing
      : "0.1px"};
  color: ${(props) =>
    props.loading
      ? "#4266C8"
      : props.customStyle.color
      ? props.customStyle.color
      : "#fff"};
`;
