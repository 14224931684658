import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getFirestore, initializeFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    databaseURL: process.env.NEXT_PUBLIC_DATA_BASE_URL,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig, "read")
const auth = getAuth(app)
await setPersistence(auth, browserLocalPersistence)
const db = initializeFirestore(app,{
    experimentalForceLongPolling: true, 
    useFetchStreams: false,
})

export {auth, db}