import {
  pushToast,
  setPageUnavailable,
  useStoreData,
} from "@/redux/onboardingSlice";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { IIFlUnavailable } from "../../constant/intermediatePageConstants";
import CTA from "../CTA";
import Icons from "../Icons";
import {
  IconContainer,
  SubmitDescription,
  SubmitPageContainer,
  SubmitTitle,
  CtaPlusContainer,
} from "./style";
export default function StageUnavailable(props) {
  const { pageUnavailable, digioRedirect } = useStoreData();
  

  const router = useRouter();
  const dispatch = useDispatch();
  const renderTitle = () => {
    return (
      <>
        <SubmitTitle>{IIFlUnavailable[pageUnavailable].title}</SubmitTitle>
        {IIFlUnavailable[pageUnavailable].description && (
          <SubmitDescription>
            {IIFlUnavailable[pageUnavailable].description}
          </SubmitDescription>
        )}
      </>
    );
  };

  const nextHandler = () => {
    if (IIFlUnavailable[pageUnavailable].type == "esign") {
      dispatch(
        pushToast({ type: "success", label: "IIFL verification complete" })
      );
    } else {
      router.push(IIFlUnavailable[pageUnavailable].nextPage);
      dispatch(setPageUnavailable(null));
    }
  };

  const renderCTA = () => {
    return (
      <>
        <CTA
          customStyle={{
            gap: "4px",
            padding: "16px 16px",

            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            background: "#4266C8",
            height: "52px",
            width: "100%",
          }}
          fixed
          onClick={() => nextHandler()}
          title={IIFlUnavailable[pageUnavailable].ctaTitle}
        />
      </>
    );
  };
  return (
    <>
      <SubmitPageContainer>
        <IconContainer>
          <Icons name={"redCloud"} />
        </IconContainer>
        {renderTitle()}
      </SubmitPageContainer>
      <CtaPlusContainer>{renderCTA()}</CtaPlusContainer>
    </>
  );
}
