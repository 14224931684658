
import { SubmitPageContainer, IconContainer, SubmitTitle } from "./style";
import Icons from '../Icons'
import { useStoreData } from "@/redux/onboardingSlice";
import { SubmittingPageDetails } from "@/constant/intermediatePageConstants";
export default function StageSubmitting(props){
  const {pageLoading} = useStoreData()
  const renderTitle = () => {
    return(
      <SubmitTitle>{SubmittingPageDetails[pageLoading].title}</SubmitTitle>
    )
  }

    return(
      <SubmitPageContainer>
      <IconContainer icon="loading">
        <Icons name={"loadingVerify"} />
      </IconContainer>
      {renderTitle()}
    </SubmitPageContainer>
    )
}