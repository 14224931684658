import styled from "styled-components";

export const MainContainer = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;

    @media only screen and (min-width: 600px){
        max-width: 455px;
        padding: 60px 40px;
        align-self: center;
        border-radius: 24px;
        margin-top: 80px;
        margin-bottom: 80px;
        box-shadow: 0px 4px 12px 0px rgba(112, 118, 135, 0.24);
    }
`