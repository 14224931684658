export const refreshEmail = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M4.59375 17.4971V14.6133H7.47742"
        stroke="#4266C8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7315 8.62109C18.449 12.437 16.1141 16.2199 12.3018 17.2416C9.38402 18.0234 6.40693 16.9717 4.59375 14.7926"
        stroke="#4266C8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.9905 11.3778C2.27299 7.56192 4.60798 3.77891 8.42023 2.75735C11.3378 1.97554 14.3147 3.02708 16.1279 5.20583"
        stroke="#4266C8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1258 2.50195V5.38583H13.2422"
        stroke="#4266C8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
