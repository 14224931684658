type TPageMapping = {
  [key: string]: {
    pageName: string;
    stage?: Number;
    supportStage?: string | null;
  };
};
export const pageMapping: TPageMapping = {
  "/verify": {
    pageName: "Verify mobile and email",
    stage: 0,
    supportStage: "Verifying mobile and email",
  },
  "/personal-details": {
    pageName: "Verify personal details",
    stage: 1,
    supportStage: "Verifying personal details",
  },
  "/personal-address": {
    pageName: "Verify personal address",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/personal-address/digilocker": {
    pageName: "Verify with DigiLocker",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/personal-address/address-proof": {
    pageName: "Upload address proof",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/personal-address/add-address": {
    pageName: "Verify personal address",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/upload-proof-v2/[proofCode]": {
    pageName: "Upload Proof",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/upload-proof-v2/2": {
    pageName: "Upload Proof",
    stage: 2,
    supportStage: "Adding personal address",
  },
  "/upload-proof-v2/65": {
    pageName: "Upload Photo Proof",
    stage: 6,
    supportStage: "Selfie Verification",
  },
  "/segment-activation": {
    pageName: "Segment Activation",
    stage: 4,
    supportStage: "Activating Segments",
  },
  "/financial-proof": {
    pageName: "Finanial Proof",
    stage: 5,
    supportStage: "Uploading Financial proof",
  },
  "/upload-proof/17": {
    pageName: "Upload Financial Proof",
    stage: 5,
    supportStage: "Uploading Financial proof",
  },
  "/upload-proof/22": {
    pageName: "Upload Financial Proof",
    stage: 5,
    supportStage: "Uploading Financial proof",
  },
  "/upload-proof/19": {
    pageName: "Upload Financial Proof",
    stage: 5,
    supportStage: "Uploading Financial proof",
  },
  "/upload-proof/21": {
    pageName: "Upload Financial Proof",
    stage: 5,
    supportStage: "Uploading Financial proof",
  },
  "/upload-proof/[proofCode]": {
    pageName: "Upload Proof",
    stage: 5,
    supportStage: null,
  },
  "/signature-verification-methods": {
    pageName: "Upload your Signature",
    stage: 7,
    supportStage: "Adding Signature",
  },
  "/bank-details": {
    pageName: "Link Bank Account",
    stage: 3,
    supportStage: "Linking Bank Account",
  },
  "/bank-proof": {
    pageName: "Upload Bank Account Proof",
    stage: 3,
    supportStage: "Linking Bank Account",
  },
  "/upload-bank-proof/22": {
    pageName: "Upload Bank Statement",
    stage: 3,
    supportStage: "Linking Bank Account",
  },
  "/upload-bank-proof/45": {
    pageName: "Upload Cancelled Cheque",
    stage: 3,
    supportStage: "Linking Bank Account",
  },
  "/upload-bank-proof/[proofCode]": {
    pageName: "Upload Bank Account Proof",
    stage: 3,
    supportStage: "Linking Bank Account",
  },
  "/": {
    pageName: "Onboarding",
    stage: 0,
    supportStage: null,
  },
  "/sign-on-screen": {
    pageName: "Sign on Screen",
    stage: 7,
    supportStage: "Signature verification",
  },
  "/esign": {
    pageName: "Esign",
    stage: 8,
    supportStage: "e-Signing application",
  },
  "/esign-status": {
    pageName: "Esign Status",
    stage: 9,
    supportStage: null,
  },
  "/loading": {
    pageName: "Loading",
    stage: 0,
    supportStage: null,
  },
  "/link": {
      pageName: "Link Existing Account",
      stage: 0,
      supportStage: null,
    },

  "/link/how-to-resolve": {
    pageName: "How to Resolve?",
    stage: 0,
    supportStage: null,
  },
  "/link/activate-segments": {
    pageName: "Link Existing Account",
    stage: 0,
    supportStage: null,
  },
  "/link/activate-segments/activate-ddpi": {
    pageName: "How to Activate DDPI",
    stage: 0,
    supportStage: null,
  },
  "/link/activate-segments/activate-fno": {
    pageName: "How to Activate FNO",
    stage: 0,
    supportStage: null,
  },

};
