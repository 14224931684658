import { pageMapping } from "@/constant/pageMapping"

declare const window: Window &
   typeof globalThis & {
    ["useronboardingchannel"]: any
}

export const supportHandler = (pageName: string) => {
    const supportStage = pageMapping[pageName].supportStage;
    let supportMessage
    if(!supportStage){
        supportMessage = 'Hi, I need help with opening IIFL Account'
    }else{
        supportMessage = `Hi, I need help with *${supportStage}* for opening IIFL Account`
    }
    const supportUri = `whatsapp://send/?phone=${process.env.NEXT_PUBLIC_SUPPORT_NUMBER}&text=${supportMessage}`
    const encodedSupportUri = encodeURI(supportUri);
    if(window['useronboardingchannel']){
        window['useronboardingchannel'].postMessage(
            encodedSupportUri
        );
    }
}