export const digiLockerFail = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M72.8975 53.0777L51.5642 14.6777C48.6975 9.51107 44.7309 6.67773 40.3642 6.67773C35.9975 6.67773 32.0309 9.51107 29.1642 14.6777L7.83085 53.0777C5.13085 57.9777 4.83085 62.6777 6.99752 66.3777C9.16418 70.0777 13.4309 72.1111 19.0309 72.1111H61.6975C67.2975 72.1111 71.5642 70.0777 73.7309 66.3777C75.8975 62.6777 75.5975 57.9444 72.8975 53.0777Z"
        fill="#FF974A"
      />
      <path
        d="M40.3691 49.1667C39.0025 49.1667 37.8691 48.0333 37.8691 46.6667V30C37.8691 28.6333 39.0025 27.5 40.3691 27.5C41.7358 27.5 42.8691 28.6333 42.8691 30V46.6667C42.8691 48.0333 41.7358 49.1667 40.3691 49.1667Z"
        fill="white"
      />
      <path
        d="M40.368 60.0012C40.168 60.0012 39.9347 59.9678 39.7013 59.9345C39.5013 59.9012 39.3013 59.8345 39.1013 59.7345C38.9013 59.6678 38.7013 59.5678 38.5013 59.4345C38.3347 59.3012 38.168 59.1678 38.0013 59.0345C37.4013 58.4012 37.0347 57.5345 37.0347 56.6678C37.0347 55.8012 37.4013 54.9345 38.0013 54.3012C38.168 54.1678 38.3347 54.0345 38.5013 53.9012C38.7013 53.7678 38.9013 53.6678 39.1013 53.6012C39.3013 53.5012 39.5013 53.4345 39.7013 53.4012C40.1347 53.3012 40.6013 53.3012 41.0013 53.4012C41.2347 53.4345 41.4347 53.5012 41.6347 53.6012C41.8347 53.6678 42.0347 53.7678 42.2347 53.9012C42.4013 54.0345 42.568 54.1678 42.7347 54.3012C43.3347 54.9345 43.7013 55.8012 43.7013 56.6678C43.7013 57.5345 43.3347 58.4012 42.7347 59.0345C42.568 59.1678 42.4013 59.3012 42.2347 59.4345C42.0347 59.5678 41.8347 59.6678 41.6347 59.7345C41.4347 59.8345 41.2347 59.9012 41.0013 59.9345C40.8013 59.9678 40.568 60.0012 40.368 60.0012Z"
        fill="white"
      />
    </svg>
  );
};
