import { mixpanelEvents } from "@/constant/mixpanelEvents";
import { mixpanelProperties } from "@/constant/mixpanelProperties";
import { handleIntermediatePage } from "@/util/handleIntermediatePage";
import { setMixpanelUserProperties, trackEvent } from "@/util/mixpanel";
import { proofMapping } from "@/constant/proofMapping";
import eligibilityCheck from "@/constant/eligibilitycheck"
import {
  Dispatch,
  AnyAction,
  MiddlewareAPI,
  PayloadAction,
} from "@reduxjs/toolkit";
import { setStage } from "./onboardingSlice";
const onboardingTrackAction = {
  ONBOARDING_VERIFYOTPTHUNK_FULFILLED: "onboarding/verifyOtpThunk/fulfilled",
  ONBOARDING_VERIFYOTPTHUNK_REJECTED: "onboarding/verifyOtpThunk/rejected",
  ONBOARDING_VERIFYBANKTHUNK_PENDING: "onboarding/verifyBankThunk/pending",
  ONBOARDING_VERIFYBANKTHUNK_FULFILLED: "onboarding/verifyBankThunk/fulfilled",
  ONBOARDING_VERIFYBANKTHUNK_REJECTED: "onboarding/verifyBankThunk/rejected",
  ONBOARDING_UPLOADPROOFTHUNK_PENDING: "onboarding/uploadProofThunk/pending",
  ONBOARDING_UPLOADPROOFTHUNK_FULFILLED:
    "onboarding/uploadProofThunk/fulfilled",
  ONBOARDING_UPLOADPROOFTHUNK_REJECTED: "onboarding/uploadProofThunk/rejected",
  ONBOARDING_STOREADDRESSTHUNK_PENDING: "onboarding/storeAddressThunk/pending",
  ONBOARDING_STOREADDRESSTHUNK_FULFILLED:
    "onboarding/storeAddressThunk/fulfilled",
  ONBOARDING_STOREADDRESSTHUNK_REJECTED:
    "onboarding/storeAddressThunk/rejected",
  ONBOARDING_VERIFYKRATHUNK_FULFILLED: "onboarding/verifyKraThunk/fulfilled",
  ONBOARDING_VERIFYKRATHUNK_REJECTED: "onboarding/verifyKraThunk/rejected",
  ONBOARDING_VERIFYPERSONALDETAILSTHUNK_FULFILLED:
    "onboarding/verifyPersonalDetailsThunk/fulfilled",
  ONBOARDING_VERIFYPERSONALDETAILSTHUNK_PENDING:
    "onboarding/verifyPersonalDetailsThunk/pending",
  ONBOARDING_GETELIGIBILITYTHUNK_FULIFLLED:
    "onboarding/getElgibilityThunk/fulfilled"
};

const onboardingTrackStatus = {
  SUCCESS: "Success",
  FAIL: "Fail",
  PENDING: "Pending",
};

const checkUserMapping = (userMappingStatus: string) => {
  switch(userMappingStatus){
    case "ELIGIBLE_USER": return onboardingTrackStatus.SUCCESS;
    case "ACTIVATED":
    case "UNMAPPED":
    case "INELIGIBLE_USER":
    case "PENDING":
      return onboardingTrackStatus.FAIL;
  }
}
export const analyticsMiddleware =
  (api: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: PayloadAction<any, string, any>) => {
    const { mobile, bankName, currentPage, proofCode, email } =
      api.getState().onboarding;
    const success = handleIntermediatePage(currentPage, proofCode);
    const proofMap = proofMapping(proofCode, success);

    if(action.type.includes('fulfilled')){
      if(action.type !== onboardingTrackAction.ONBOARDING_VERIFYOTPTHUNK_FULFILLED)
      if(action?.payload?.stage){
        api.dispatch(setStage({stage: action.payload.stage}))
      }
    }

    switch (action.type) {
      case onboardingTrackAction.ONBOARDING_VERIFYOTPTHUNK_FULFILLED: {
        trackEvent({
          eventName:
            mixpanelEvents.MIXPANEL_VIEW_ACCOUNT_OPENING_OTP_CONFIRMATION,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
            [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
          },
        });
        break;
      }

      case onboardingTrackAction.ONBOARDING_VERIFYOTPTHUNK_REJECTED: {
        if (action.payload.message !== undefined) {
          trackEvent({
            eventName:
              mixpanelEvents.MIXPANEL_VIEW_ACCOUNT_OPENING_OTP_CONFIRMATION,
            eventDetails: {
              [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
              [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
              [mixpanelProperties.ERROR]: action.payload?.message,
            },
          });
        } else if (action.payload.errors) {
          Object.values(action.payload.errors).map((error: any) => {
            trackEvent({
              eventName:
                mixpanelEvents.MIXPANEL_VIEW_ACCOUNT_OPENING_OTP_CONFIRMATION,
              eventDetails: {
                [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
                [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
                [mixpanelProperties.ERROR]: error?.message,
              },
            });
          });
        }

        break;
      }

      case onboardingTrackAction.ONBOARDING_VERIFYBANKTHUNK_FULFILLED: {
        trackEvent({
          eventName:
            mixpanelEvents.MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT_CONFIRMATION,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
            [mixpanelProperties.SELECTION]: bankName,
          },
        });

        break;
      }
      case onboardingTrackAction.ONBOARDING_VERIFYBANKTHUNK_PENDING: {
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT,
          eventDetails: {
            [mixpanelProperties.SELECTION]: bankName,
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
          },
        });
        break;
      }
      case onboardingTrackAction.ONBOARDING_VERIFYBANKTHUNK_REJECTED: {
        if (action.payload.message !== undefined) {
          trackEvent({
            eventName:
              mixpanelEvents.MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT_CONFIRMATION,
            eventDetails: {
              [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
              [mixpanelProperties.SELECTION]: bankName,
              [mixpanelProperties.ERROR]: action.payload?.message,
            },
          });
        } else if (action.payload.errors) {
          Object.values(action.payload.errors).map((error: any) => {
            trackEvent({
              eventName:
                mixpanelEvents.MIXPANEL_VIEW_VERIFYING_BANK_ACCOUNT_CONFIRMATION,
              eventDetails: {
                [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
                [mixpanelProperties.SELECTION]: bankName,
                [mixpanelProperties.ERROR]: error?.message,
              },
            });
          });
        }

        break;
      }
      case onboardingTrackAction.ONBOARDING_UPLOADPROOFTHUNK_FULFILLED: {
        trackEvent({
          eventName: proofMap.successEvent,
          eventDetails: {
            [mixpanelProperties.SELECTION]: proofMap.name,
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
          },
        });

        break;
      }
      case onboardingTrackAction.ONBOARDING_UPLOADPROOFTHUNK_PENDING: {
        trackEvent({
          eventName: proofMap.submittingEvent,
          eventDetails: {
            [mixpanelProperties.SELECTION]: proofMap.name,
          },
        });
        break;
      }
      case onboardingTrackAction.ONBOARDING_UPLOADPROOFTHUNK_REJECTED: {
        if (action.payload.message !== undefined) {
          trackEvent({
            eventName: proofMap.successEvent,
            eventDetails: {
              [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
              [mixpanelProperties.SELECTION]: proofMap.name,
              [mixpanelProperties.ERROR]: action.payload?.message,
            },
          });
        } else if (action.payload.errors) {
          Object.values(action.payload.errors).map((error: any) => {
            trackEvent({
              eventName: proofMap.successEvent,
              eventDetails: {
                [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
                [mixpanelProperties.SELECTION]: proofMap.name,
                [mixpanelProperties.ERROR]: action.payload.error?.message,
              },
            });
          });
        }

        break;
      }
      case onboardingTrackAction.ONBOARDING_STOREADDRESSTHUNK_FULFILLED: {
        const { address1, address2, city, pinCode, state } = action.meta.arg;
        trackEvent({
          eventName:
            mixpanelEvents.MIXPANEL_VIEW_ADDRESS_SUBMISSION_CONFIRMATION,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
            [mixpanelProperties.ACCOUNT_OPENING_ADDRESS]:
              address1 + ", " + address2,
            [mixpanelProperties.ACCOUNT_OPENING_CITY]: city,
            [mixpanelProperties.ACCOUNT_OPENING_PINCODE]: pinCode,
            [mixpanelProperties.ACCOUNT_OPENING_STATE]: state,
          },
        });

        break;
      }
      case onboardingTrackAction.ONBOARDING_STOREADDRESSTHUNK_PENDING: {
        const { address1, address2, city, pinCode, state } = action.meta.arg;
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_ADDRESS,
          eventDetails: {
            [mixpanelProperties.ACCOUNT_OPENING_ADDRESS]:
              address1 + ", " + address2,
            [mixpanelProperties.ACCOUNT_OPENING_CITY]: city,
            [mixpanelProperties.ACCOUNT_OPENING_PINCODE]: pinCode,
            [mixpanelProperties.ACCOUNT_OPENING_STATE]: state,
          },
        });
        break;
      }
      case onboardingTrackAction.ONBOARDING_STOREADDRESSTHUNK_REJECTED: {
        const { address1, address2, city, pinCode, state } = action.meta.arg;
        if (action.payload.message !== undefined) {
          trackEvent({
            eventName:
              mixpanelEvents.MIXPANEL_VIEW_ADDRESS_SUBMISSION_CONFIRMATION,
            eventDetails: {
              [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
              [mixpanelProperties.ACCOUNT_OPENING_ADDRESS]:
                address1 + ", " + address2,
              [mixpanelProperties.ACCOUNT_OPENING_CITY]: city,
              [mixpanelProperties.ACCOUNT_OPENING_PINCODE]: pinCode,
              [mixpanelProperties.ACCOUNT_OPENING_STATE]: state,
              [mixpanelProperties.ERROR]: action.payload?.message,
            },
          });
        } else if (action.payload.errors) {
          Object.values(action.payload.errors).map((error: any) => {
            trackEvent({
              eventName:
                mixpanelEvents.MIXPANEL_VIEW_ADDRESS_SUBMISSION_CONFIRMATION,
              eventDetails: {
                [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
                [mixpanelProperties.ACCOUNT_OPENING_ADDRESS]:
                  address1 + ", " + address2,
                [mixpanelProperties.ACCOUNT_OPENING_CITY]: city,
                [mixpanelProperties.ACCOUNT_OPENING_PINCODE]: pinCode,
                [mixpanelProperties.ACCOUNT_OPENING_STATE]: state,
                [mixpanelProperties.ERROR]: error?.message,
              },
            });
          });
        }
        break;
      }
      case onboardingTrackAction.ONBOARDING_VERIFYKRATHUNK_FULFILLED: {
        const { pan, dob } = action.meta.arg;
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_VIEW_KRA_SUBMISSION,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
            [mixpanelProperties.PAN_NUMBER]: pan,
            [mixpanelProperties.DOB]: dob,
            [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
            [mixpanelProperties.EMAIL]: email
          },
        });
        setMixpanelUserProperties({
          properties: {
            [mixpanelProperties.PAN_NUMBER]: pan,
            [mixpanelProperties.DOB]: dob,
          },
        });
        break;
      }

      case onboardingTrackAction.ONBOARDING_VERIFYKRATHUNK_REJECTED: {
        const { pan, dob } = action.meta.arg;
        if (action.payload.message !== undefined) {
          trackEvent({
            eventName: mixpanelEvents.MIXPANEL_VIEW_KRA_SUBMISSION,
            eventDetails: {
              [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
              [mixpanelProperties.PAN_NUMBER]: pan,
              [mixpanelProperties.DOB]: dob,
              [mixpanelProperties.ERROR]: action.payload?.message,
            },
          });
        } else if (action.payload.errors) {
          Object.values(action.payload.errors).map((error: any) => {
            trackEvent({
              eventName: mixpanelEvents.MIXPANEL_VIEW_KRA_SUBMISSION,
              eventDetails: {
                [mixpanelProperties.STATUS]: onboardingTrackStatus.FAIL,
                [mixpanelProperties.PAN_NUMBER]: pan,
                [mixpanelProperties.DOB]: dob,
                [mixpanelProperties.ERROR]: error?.message,
              },
            });
          });
        }
        break;
      }
      case onboardingTrackAction.ONBOARDING_VERIFYPERSONALDETAILSTHUNK_PENDING: {
        trackEvent({
          eventName:
            mixpanelEvents.MIXPANEL_VIEW_ACCOUNT_DETAILS_SUBMITTING_SCREEN,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.PENDING,
            [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
            [mixpanelProperties.EMAIL]: email,
            [mixpanelProperties.FATHER_NAME]: action.meta.arg.fatherName,
            [mixpanelProperties.GENDER]: action.meta.arg.gender,
            [mixpanelProperties.MARITAL_STATUS]: action.meta.arg.maritalStatus,
            [mixpanelProperties.PAN_NUMBER]: action.meta.arg.pan,
            [mixpanelProperties.DOB]: action.meta.arg.dob,
          },
        });
        break;
      }

      case onboardingTrackAction.ONBOARDING_VERIFYPERSONALDETAILSTHUNK_FULFILLED: {
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_VIEW_ACCOUNT_DETAILS_SUBMISSION,
          eventDetails: {
            [mixpanelProperties.STATUS]: onboardingTrackStatus.SUCCESS,
            [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
            [mixpanelProperties.EMAIL]: email,
            [mixpanelProperties.FATHER_NAME]: action.meta.arg.fatherName,
            [mixpanelProperties.GENDER]: action.meta.arg.gender,
            [mixpanelProperties.MARITAL_STATUS]: action.meta.arg.maritalStatus,
            [mixpanelProperties.PAN_NUMBER]: action.meta.arg.pan,
            [mixpanelProperties.DOB]: action.meta.arg.dob,
          },
        });

        setMixpanelUserProperties({
          properties: {
            [mixpanelProperties.ACCOUNT_OPENING_MOBILE]: mobile,
            [mixpanelProperties.EMAIL]: email,
            [mixpanelProperties.FATHER_NAME]: action.meta.arg.fatherName,
            [mixpanelProperties.GENDER]: action.meta.arg.gender,
            [mixpanelProperties.MARITAL_STATUS]: action.meta.arg.maritalStatus,
          },
        });
        break;
      }

      case onboardingTrackAction.ONBOARDING_GETELIGIBILITYTHUNK_FULIFLLED: {
        trackEvent({
          eventName: mixpanelEvents.MIXPANEL_VIEW_ELIGIBILITY_CONFIRMATION,
          eventDetails: checkUserMapping(action.payload.userMappingStatus) == onboardingTrackStatus.SUCCESS ? {
            [mixpanelProperties.STATUS]: checkUserMapping(action.payload.userMappingStatus),
          } : {
            [mixpanelProperties.STATUS]: checkUserMapping(action.payload.userMappingStatus),
            [mixpanelProperties.ERROR]: eligibilityCheck?.[action.payload.userMappingStatus]?.title
          },
        });
        break;
      }
    }
    return next(action);
  };
