import { styled } from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px;
    background-color: #fff;
    @media only screen and (min-width: 600px){
        position: unset;
        width: 100%;
    }
`