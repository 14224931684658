import styled from "styled-components";

export const OwnerModalContainer = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const OwnerCard = styled.h3`
  all: unset;
  color: #171725;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 16px;
  font-style: normal;
  font-weight: ${(props) => (props.isBold ? "700" : "500")};
  line-height: normal;
  letter-spacing: -0.1px;
`;
