declare const window: Window &
   typeof globalThis & {
    ["useronboardingchannel"]: any
}
export const closeEvent = () => {

    if(window['useronboardingchannel']){
        try{
            window['useronboardingchannel'].postMessage(
                'close-webview'
            );
        }catch(err){
            console.log(err)
        }
        
    }
}