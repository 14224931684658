import { INITIAL_STATE } from "./onboardingInitialStateTypes";
import { UserMappingStatus } from "./onboardingResponseTypes";

export const initialState: INITIAL_STATE = {
  stage: "",
  loading: false,
  marketfeedUser: null,
  accessToken: null,
  error: false,
  apiError: {},
  otpSent: false,
  showModal: false,
  modalType: null,
  displaySearch: false,
  searchType: "",
  intermediatePage: null,
  isLoggedIn: false,
  clientCode: null,
  preferredBankList: [],
  bankList: [],
  searchBankList: [],
  proofCode: null,
  branchIfscList: [],
  signatureMethod: "Sign on Screen",
  uploadType: "",
  segmentPageCheckBox: { checkDerivatives: false, checkDDPI: false },
  bankName: null,
  pageError: null,
  pageLoading: null,
  pageSuccess: null,
  pageUnavailable: null,
  toast: null,
  nextPage: null,
  currentPage: '',
  digiLockerRedirect: null,
  addressProofFiles: null,
  mobile: null,
  email: null,
  isKraVerified: 0,
  isPreviewingDocument: false,
  digioRedirect: null,
  emailVerified: false,
  refreshLoading: false,
  keyboardResize: false,
  marketfeedDetails: null,
  otpVerified: false,
  branchLoading: false,
  consentType: "Self",
  consentData: [],
  mobileConsent:"SE",
  emailConsent:"SE",
  initialLoading: (process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT && !JSON.parse(process.env.NEXT_PUBLIC_TESTING_ENVIRONMENT)) ? true : false,
  bankVerificationLimitExceeded: false,
  dob: null,
  unavailableTryAgain: null,
  apiLoading: false,
  eligibility: null,
  showEligibility:false,
  fnoActivated:false,
  ddpiActivated:false,
  clientName:null,
}; 
