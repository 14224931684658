import {
  VerifyEmailComponent,
  VerifyEmailContainer,
  VerifyBottom,
} from "./style";
import Icons from "../Icons";
import CTA from "../CTA";
import { useDispatch } from "react-redux";
import { authenticateEmailThunk, resendEmailVerificationThunk } from "@/redux/onboardingThunks";
import { useStoreData } from "@/redux/onboardingSlice";
import { trackEvent } from "../../util/mixpanel";
import { mixpanelEvents } from "@/constant/mixpanelEvents";
import { mixpanelProperties } from "@/constant/mixpanelProperties";
import { useEffect, useRef, useState } from "react";
import { resendEmailVerification } from "@/services/api/api";

const VerifyEmail = (props) => {
  const dispatch = useDispatch()
  const {refreshLoading, email} = useStoreData()
  

  const resendEmailLink = () => {
    trackEvent({
      eventName: mixpanelEvents.MIXPANEL_RESEND_ACCOUNT_OPENING_EMAIL_LINK,
      eventDetails: {
        [mixpanelProperties.EMAIL]: email
      }
    })
    dispatch(resendEmailVerificationThunk({}))
  }

  const refreshEmailVerification = () =>{
    trackEvent({
      eventName: mixpanelEvents.MIXPANEL_REFRESH_ACCOUNT_OPENING_EMAIL_VERIFICATION,
      eventDetails: {
        [mixpanelProperties.EMAIL]: email
      }
    })
    dispatch(authenticateEmailThunk({toast: true}))
  }

  const resendHandler = () => {
    if(props.resendActive){
      resendEmailLink();
      props.startTimer();
    }
  }
  return (
    <VerifyEmailComponent>
      <VerifyEmailContainer>
        <div className="top">
          <Icons name="checkingEmail" />
          <span className="title">Check Your Email</span>
          <span className="subtitle">
            Click the verification link sent to{" "}
            <span className="email">{email}</span>
            <br/>Check spam
            folder if not received
          </span>
        </div>
        <div className="bottom">
          {props.resendActive && <span className={`resend ${props.resendActive && 'animateIn'}`} onClick={() => resendHandler()}>Resend Link</span>}
          {!props.resendActive && <span className={`resendIn ${!props.resendActive && 'animateOut'}`}>Resend Link <span className="secondsTimer">in {props.time} sec</span></span>}
        </div>
      </VerifyEmailContainer>
      <VerifyBottom>
        <span className="already">Already Verified?</span>
        <div className="bottomCTA">
          <CTA
            customStyle={{
              gap: "4px",
              padding: "16px 16px",
              fontSize: "16px",
              fontWeight: "700",
              lineHeight: "20px",
              letterSpacing: "0.1px",
              borderRadius: "12px",
              background: "#4266C8",
              height: "52px",
              width: "100%",
              color: "#4266C8",
            }}
            clickDependency={[props.resendActive]}
            title={"Refresh"}
            ghost={true}
            leftIcon={"refreshEmail"}
            type={"refresh"}
            loading={refreshLoading}
            onClick={() => refreshEmailVerification()}
          />
        </div>
      </VerifyBottom>
    </VerifyEmailComponent>
  );
};

export default VerifyEmail;
