import {
  useStoreData,
  setconsentType,
  setShowModal,
  setEmailAndMobileConsentType,
} from "@/redux/onboardingSlice";
import { OwnerModalContainer, OwnerCard } from "./style";
import { useDispatch } from "react-redux";
const AccountOwnerModal = () => {
  const { consentType, consentData } = useStoreData();
  const dispatch = useDispatch();
  return (
    <>
      <OwnerModalContainer>
        {consentData.map((owner) => {
          return (
            <>
              <OwnerCard
                onClick={() => {
                  dispatch(setShowModal({ show: false, type: "" }));
                  dispatch(setconsentType(owner.declarationValue));
                  dispatch(
                    setEmailAndMobileConsentType({
                      emailConsent: owner.declarationCode,
                      mobileConsent: owner.declarationCode,
                    })
                  );
                }}
                isBold={consentType === owner.declarationValue ? true : false}
              >
                {owner.declarationValue}
              </OwnerCard>
            </>
          );
        })}
      </OwnerModalContainer>
    </>
  );
};

export default AccountOwnerModal;
