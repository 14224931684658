import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {CalendarStyle} from './style'
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setDob, setShowModal, useStoreData } from '@/redux/onboardingSlice';
export default function CalendarComponent(props){
    const {dob} = useStoreData()
    const dispatch = useDispatch()
    const changeHandler = (e) => {
        dispatch(setDob(e));
        dispatch(setShowModal({type: null, show: false}))
    }
    return(
        <CalendarStyle>
            <Calendar onChange={(e) => changeHandler(e)} value={dob ? dob : null}/>
        </CalendarStyle>
        
    )
}