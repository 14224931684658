import store from "@/redux/onboardingStore";
import { Provider } from "react-redux";
import "./style.css";
import "./font.css";
import Header from "@/components/Header";
import BottomModal from "@/components/BottomModal";
import SubmitVerifyPage from "@/components/SubmitVerifyPage"
import { useEffect } from "react";
import { auth } from "../services/firebase/fire";
import FirebaseAuthChange from "../components/FirebaseAuthChange";
import IntermediatePages from "../components/IntermediatePages"
import Toast from "../components/Toast"
import PageChangeListener from "../components/PageChangeListener"
import LogRocket from 'logrocket';
import {isDesktop} from 'react-device-detect';
import {MainContainer} from "./style"



const MyApp = ({ Component, pageProps, query }) => {
  useEffect(() => {
    LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_ID);
  }, [])

  return (
    <Provider store={store}>
      <FirebaseAuthChange>
      <PageChangeListener queries={query}/>
      <IntermediatePages />
        <Header isDesktop={isDesktop} stage={0} />
        <Toast />
        <MainContainer>
        <Component {...pageProps} query={query} isDesktop={isDesktop} />
        </MainContainer>
        <BottomModal isDesktop={isDesktop}/>
      </FirebaseAuthChange> 
    </Provider>
  );
}

MyApp.getInitialProps = async (ctx) => {
  const {query} = ctx.router
  return {query}
}

export default MyApp
