import { styled } from "styled-components";

export const SubmitPageContainer = styled.div`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 658px;
  padding: 16px 40px;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  width: ${(props) => (props.icon ? " 66.67px" : "80px")};
  height: ${(props) => (props.icon ? "66.67px" : "80px")};
  padding: ${(props) => (props.icon ? "14.817px" : "0px")};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 370.389px;

  background: ${(props) =>
    props.icon ? "rgba(129, 152, 218, 0.2)" : "rgba(129, 152, 218, 0.0)"};
`;
export const SubmitTitle = styled.h2`
  all: unset;
  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SubmitSubtitle = styled.span`
    color: #707687;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Subtitle Regular */
    font-family: Satoshi-Variable;
    font-size: 14px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
    letter-spacing: -0.1px;
`
