import styled from "styled-components";

export const CalendarStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px;

    .react-calendar{
        border: none;
    }
`