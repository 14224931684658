import {
  TermsAndConditionContainer,
  TermsContentContainer,
  TermsContent,
  TermsContentTitle,
  ConditionContentListLetters,
  ConditionContentListLettersItem,
} from "./style";
import CTA from "../CTA/index";
import { useDispatch } from "react-redux";
import { setShowModal } from "@/redux/onboardingSlice";
const TermsAndConditionModal = () => {
  const dispatch = useDispatch();

  return (
    <>
      <TermsAndConditionContainer>
        <TermsContentContainer>
          <TermsContent>
            IIFL Securities Limited (hereinafter referred to as IIFL) provides
            for “Do It Yourself “concept of internet trading thereby reducing
            the hassle of paperwork for on boarding client and providing
            paperless and seamless services to its clients. IIFL has prescribed
            certain online product features, eligibility criteria and terms and
            conditions which the client should understand and aware, before
            opening trading and demat account through the website of IIFL. The
            Client is required to go through each and every condition as
            mentioned below and validate if he/she is eligible to open account
            with IIFL.
            <span className="bolded">
              If all terms and conditions are acceptable to the client, he/she
              should mark in I agree and accept column in order to open trading
              and demat account.
            </span>
            Following are some of the unique features of the online product on
            offer by IIFL
          </TermsContent>
          <TermsContent>
            <TermsContentTitle>Product Features</TermsContentTitle>
            <ConditionContentListLetters>
              <ConditionContentListLettersItem>
                The product being offered is completely on online, where by
                client would be able to trade online and experience the other
                services provided by IIFL online terminal and/or through mobile
                application. Further, the online terminal and mobile application
                also allows the client modify the service request for various
                alerts/ communication, pay-out requests, settlement frequency
                etc.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                This facility is currently available for opening Resident
                Individual broking and demat account only. Please note Non
                Individual and NRIs cannot open broking and demat account
                through this facility.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                The client is required to upload all documents, records etc.
                which shall be verified with the originals / authenticated as
                per prevailing regulatory guidelines as stipulated in Know Your
                Client documents and make payment via online mode through his
                registered Bank Account in order to open account.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                In case the documents submitted along with the application do
                not comply with the KYC guidelines of IIFL, the application is
                liable to be rejected and IIFL will not be liable to refund the
                Account opening fees and any interest on the amount.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                IIFL shall reject the application if it is found that the client
                is barred from trading or any investigation from SEBI or any
                other regulatory authorities is pending against the said client,
                decision taken by IIFL shall be final and binding upon the
                client. IIFL does not guarantee the account opening form
                submitted by the client shall be duly accepted as the company
                reserves its right to reject account opening application of the
                client without assigning any reason.
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                The account will be opened in the same combination as provided
                in the Account Opening Form by the Customer or may be updated as
                per the details updated with UIDAI (Aadhar) or with KRA. The
                client shall verify the data fetched through KRA / UIDAI
                database and confirm that data available is true, correct and
                updated. The client is responsible to intimate any changes or
                modification in the details fetched to IIFL, along-with
                necessary documentary evidence.
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                The Client shall be required to affix his own digital signature
                e-sign at the relevant pages of account opening form so as to
                enable IIFL to open the said account.
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                The margin trading funding (MTF) will be provided as bundled
                offering along-with the activation for trading in Cash Market.
                The client would acquaint themselves with the terms and
                conditions of Margin Trading Funding provided by IIFL. The
                client hereby agrees to be bind by such provisions as outlined
                in these documents and act in accordance with the same while
                availing the margin trading funding. The client hereby undertake
                to refer to the updated policies and procedure as posted on IIFL
                Securities website and abide by the same.
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                <span className="newBullet">
                  Account Number/ Login Id/ User Id
                </span>
                <ConditionContentListLetters
                  className="bullet "
                  style={{ marginTop: "20px" }}
                >
                  <ConditionContentListLettersItem>
                    The account number allotted to you is in inactive status to
                    avoid any unauthorised transactions. Transaction will be
                    allowed post activation of your account after completion of
                    account opening formalities. In case the documents submitted
                    along with the application do not comply with the KYC
                    guidelines of IIFL, the application is liable to be
                    rejected.
                  </ConditionContentListLettersItem>
                  <ConditionContentListLettersItem>
                    The User Id – chosen by you or allocated to you will remain
                    available for 30 days from the date of selection or
                    allocation of the account number. In case the account does
                    not get activated within 30 days the User ID will not be
                    available for the customer.
                  </ConditionContentListLettersItem>
                  <ConditionContentListLettersItem>
                    As per IIFL Policy we can provide only one User id/Login id
                    for a client opening trading account in Equity. If you are
                    already registered in commodity and you want to open Equity
                    account or vice versa, please note you are requested to use
                    your existing User id/Login Id and complete the account
                    opening formalities.
                  </ConditionContentListLettersItem>
                </ConditionContentListLetters>
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                <span className="newBullet">Login Confidentiality:</span> The
                Client agree and confirm that any instructions set via online
                trading account, which requires client to enter login
                credentials is known only to client and no other person. Client
                must not share trading / demat account details/login credentials
                with any other person. The client shall be wholly responsible in
                case the login credentials are compromised and shared with any
                person. IIFL or any of its agents or employees, will not ask for
                any login details or Passwords from you in any manner
                whatsoever.
              </ConditionContentListLettersItem>{" "}
              <ConditionContentListLettersItem>
                All the communication such as confirmation of orders/trades,
                margin calls, decisions/calls to liquidate the collateral/
                positions / security, ECN (Electronic Contract Notes), DP
                Transaction cum Holding statements and such other statements
                pertaining to Equity and Demat Operations and services including
                alerts, e-newsletter and promotional mails etc. would be send on
                the registered email address or registered mobile number with
                IIFL.
              </ConditionContentListLettersItem>
            </ConditionContentListLetters>
            <TermsContentTitle>
              Authorization and Acknowledgement
            </TermsContentTitle>
            <TermsContent>
              The client hereby understands and acknowledge that by agreeing to
              enter into Client-Broker relationship with IIFL Securities
              Limited, he would have access to product uniqueness and features
              only if the following conditions are met:
            </TermsContent>
            <ConditionContentListLetters className="bulletNumbers">
              <ConditionContentListLettersItem>
                I confirm that I am an Indian Resident, born in India and my
                sole country of tax residency is India. I am not US
                person/Resident of Canada/ countries falling under CRS thus
                FATCA & CRS Declaration is not applicable to me.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we am neither politically exposed person, nor related to one.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we understand and acknowledge that I/we will receive credits
                automatically into my/our demat account maintained with IIFL and
                I/we would like to receive dividend/interest directly to my bank
                account given during the account opening process through ECS.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we understand and acknowledge that, I/we would like to
                subscribe for Account Statement (for demat) with monthly
                periodicity.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we understand and acknowledge that the PoA/DDPI or any such
                authorization/ Instruction in favour of IIFL is facilitates
                seamless trading experience including collection of margins as
                well as settlement of funds and securities.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we authorise that by sharing the Aadhaar Number voluntarily,
                I/we have allowed IIFL to access details with UIDAI database and
                use the data (my data / information / photograph etc.) so
                fetched to complete KYC and do due diligence as required and
                mandated by SEBI and other regulators.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/we authorise IIFL to use the Aadhaar Number for generating
                e-signature with the help of any certifying authority and affix
                the same at the relevant places in the KYC form. The e-signature
                so affixed in the KYC form and other voluntary documents would
                be equivalent of my agreement to all the terms and conditions as
                it would have been, had the form be signed by me physically.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/We understand that IIFL may at it absolute discretion,
                discontinue any of the Services completely or partially without
                any notice to me/us.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/We have read and understood the terms and conditions,
                disclaimers, privacy policy available at IIFL"s web-site
                www.indiainfoline.com.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                There are no action/proceedings initiated/pending/taken by
                FMC/SEBI/Stock exchange/any other authority against me for
                dealing in securities markets during the last 3 years.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I/We intend to invest in stock/commodity market with Own Funds
                only.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                DIS booklet- I do not wish to obtain DIS for the time being.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I hereby request you to consider the data like Proof of Identity
                (POI) and Proof of address (P0A) and any other information
                downloaded from other sources like KRA / CERSAI.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I hereby authorise IIFL to pledge / Re-pledge my
                shares/securities with Clearing Member/Clearing Corporation/
                Clearing House in accordance with regulatory guidelines.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                The client can fund their account online, from specific list of
                Payment Gateway options. The amount will be credited into
                customer account post account activation and basis availability
                of clear funds or receipt of the same by IIFL. While
                transferring funds through online mode(s), IIFL will not accept
                any responsibility or liability of whatsoever nature in respect
                of any loss or damage arising directly or indirectly to the
                customer out of the decline due to lack of authorization for any
                transaction/s, or exceeding the pre-set limit mutually agreed by
                the customer and his/her remitting "Bank(s)", or any payment
                issues arising out of the transaction, or decline of transaction
                for any other reason/s.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                The trading in the derivative segment as opted by the client at
                the time of KYC will be activated once the income proof in the
                form of holdings in the clients’ demat account is available.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I authorise to IIFL to offer and provide services of other
                products like mutual fund units and other securities and
                services.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                I confirm that before e-signing the EKYC documents, I will go
                through the entire KYC and agree that all my information &
                details is captured properly on the account opening documents.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                The client confirms that he/she is not Illiterate / Disabled
                Person / Blind person /Person with mental disability.
              </ConditionContentListLettersItem>
              <ConditionContentListLettersItem>
                The applicable Brokerage, DP Tariff & other charges to my
                account are explained to me and I am aware that the same can be
                modified under intimation to me.
              </ConditionContentListLettersItem>
            </ConditionContentListLetters>
          </TermsContent>
        </TermsContentContainer>{" "}
        <CTA
          customStyle={{
            gap: "8px",
            padding: "16px 16px",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "0.1px",
            borderRadius: "12px",
            height: "52px",
            width: "100%",
            color: "#4266C8",
          }}
          title={"Close"}
          fixed={"bottom"}
          ghost
          onClick={() => {
            dispatch(setShowModal({ show: false, type: "" }));
          }}
        />
      </TermsAndConditionContainer>
    </>
  );
};
export default TermsAndConditionModal;
