import { Container, IconContainer, HeaderText, ParagraphText } from "./style";
import Icons from "../Icons";
import CTA from "../CTA";
import { useDispatch } from "react-redux";
import { setShowModal } from "@/redux/onboardingSlice";

import { useRouter } from "next/router";
import { PAGES } from "@/constant/misc";

const DigiLockerFailed = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();

  return (
    <>
      <Container>
        <IconContainer>
          <Icons name="digiLockerFail" />
        </IconContainer>
        <HeaderText>
          Address Fetch from <br />
          DigiLocker Failed
        </HeaderText>
        <ParagraphText>
          You need to enter the address manually <br />
          and upload proof with the same address
        </ParagraphText>
      </Container>
      <CTA
        customStyle={{
          gap: "8px",
          padding: "16px 16px",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.1px",
          borderRadius: "12px",
          height: "52px",
          width: "100%",
          color: "#FFF",
        }}
        title={"Add Address Manually"}
        fixed={"bottom"}
        onClick={() => {
          router.push(PAGES.PERSONAL_ADDRESS_ADDRESS_PROOF);
         dispatch(setShowModal({ show: false, type: "" }))

        }}
        rightIcon="frontArrow"
      />
    </>
  );
};

export default DigiLockerFailed;
