import { styled } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 24px;
  margin-bottom: 76px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`;
export const HeaderText = styled.div`
  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-family: Satoshi-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ParagraphText = styled.div`
  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  letter-spacing: -0.1px;
`;
