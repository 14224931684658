import {
  ModalAnimation,
  ModalBody,
  ModalContainer,
  ModalLabel,
  ModalMask,
} from "./style";
import Icons from "../Icons";
import {
  setShowModal,
  setUploadType,
  useStoreData,
} from "@/redux/onboardingSlice";
import { useDispatch } from "react-redux";
import VerifyEmail from "@/components/VerifyEmail";
import SegementModalType from "@/components/SegementModalType";
import { useEffect, useRef, useState } from "react";
import UploadType from "@/components/UploadType";
import DigiLockerFailed from "@/components/DigiLockerFailedModal";
import AccountOwnerModal from "@/components/AccountOwnerModal";
import IIFLUnavailableModal from "@/components/IIFLUnavailableModal";
import CalendarComponent from "@/components/CalendarComponent";
import TermsAndConditionModal from "@/components/TermsAndConditionModal/index";
import EligibilityCheck from "@/components/EligibilityModal/index";
const Modal = (props) => {
  const dispatch = useDispatch();
  const { showModal, modalType } = useStoreData();
  const [showModalLocal, setShowModalLocal] = useState();
  const [modalTypeLocal, setModalTypeLocal] = useState(modalType);
  const [resendActive, setResendActive] = useState(true);

  const [time, setTime] = useState(30);

  const timerRef = useRef();

  const startTimer = () => {
    setResendActive(false);
    setTime(30);
    timerRef.current = setInterval(() => {
      setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);
  };

  useEffect(() => {
    if (time < 1) {
      clearInterval(timerRef.current);
      setResendActive(true);
    }
  }, [time]);

  const modalContent = () => {
    switch (modalTypeLocal) {
      case "verifyEmail":
        return (
          <VerifyEmail
            startTimer={startTimer}
            time={time}
            resendActive={resendActive}
          />
        );
      case "uploadType":
        return <UploadType />;
      case "riskDisclosures":
        return <SegementModalType type="riskDisclosures" ctaTitle="I Agree" />;
      case "segmentLearnMore":
        return <SegementModalType type="segmentLearnMore" ctaTitle="Got it" />;
      case "digiLockerFailed":
        return <DigiLockerFailed />;
      case "accountOwner":
        return <AccountOwnerModal />;
      case "iiflUnavailable":
        return <IIFLUnavailableModal />;
      case "calendar":
        return <CalendarComponent />;
      case "termsAndCondition":
        return <TermsAndConditionModal />;
 
    }
  };

  const modalLabel = () => {
    switch (modalTypeLocal) {
      case "verifyEmail":
        return "Verify Your Email Address";
      case "uploadType":
        return "Upload Your File";
      case "accountOwner":
        return "Account belongs to";
      case "calendar":
        return "Date of birth";
      case "termsAndCondition":
        return "Terms and Conditions";
      default:
        return null;
    }
  };
  useEffect(() => {
    if (showModal) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "scroll";
    }
  }, [showModal]);
  useEffect(() => {
    if (!showModal) {
      setTimeout(() => {
        setShowModalLocal(false);
      }, 100);
    } else {
      setShowModalLocal(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (!modalType) {
      setTimeout(() => {
        setModalTypeLocal("");
      }, 200);
    } else {
      setModalTypeLocal(modalType);
    }
  }, [modalType]);

  if (showModalLocal)
    return (
      <>
        <ModalMask
          onClick={() => {
            dispatch(setShowModal({ show: false, type: "" }));
            dispatch(setUploadType(""));
          }}
        />
        <ModalAnimation>
          <div className={showModal ? "fadeIn" : "fadeOut"}>
            <ModalContainer>
              <ModalLabel label={modalLabel()}>
                {modalLabel() ? (
                  <span className="label">{modalLabel()}</span>
                ) : (
                  <div></div>
                )}
                <span
                  className="icon"
                  onClick={() =>
                    dispatch(setShowModal({ show: false, type: "" }))
                  }
                >
                  <Icons name="modalClose" />
                </span>
              </ModalLabel>
              <ModalBody>{modalContent()}</ModalBody>
            </ModalContainer>
          </div>
        </ModalAnimation>
      </>
    );
};

export default Modal;
