import { auth, db } from "./fire";
import { signInWithCustomToken } from "firebase/auth";

export async function customTokenLogin(token){
    try{
        const userId = signInWithCustomToken(auth, token)
        return userId
    }
    catch(error){
        return error
    }
}