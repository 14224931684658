import styled from "styled-components";

export const ToastMain = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 100;
`

export const ToastContainer = styled.div`
    padding: 12px;
    border-radius: 12px;
    border: ${(props) => props.type == 'caution' ? '1px solid #FF974A' : props.type == 'fail' ? '1px solid #FD4F4F' : '1px solid #18B777'};
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(23, 23, 37, 0.24);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    animation: 0.5s ease-out 0s 1 slideInFromTop;
    .icon{
        display: flex;
        align-items: center;
        width: 20px;
    }

    .label{
        color: #171725;
        font-feature-settings: 'clig' off, 'liga' off;
        /* Subtitle Medium */
        font-family: Satoshi-Variable;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.1px;
    }

    @keyframes slideInFromTop {
        0% {
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
    }
`   