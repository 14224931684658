export const branchIcon = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Branch-Icon">
<g id="vuesax/bulk/building-3">
<g id="building-3">
<path id="Vector" opacity="0.4" d="M17.5117 17.2101V25.6568L3.51168 25.6801V17.2101L3.5 15.4601V8.25012C3.5 7.46846 3.89668 6.74508 4.53835 6.31342L9.20502 3.19844C9.98668 2.67344 11.0133 2.67344 11.795 3.19844L16.4616 6.31342C17.115 6.74508 17.5 7.46846 17.5 8.25012V15.4601L17.5117 17.2101Z" fill="#707687"/>
<path id="Vector_2" d="M25.6663 24.7953H24.1847V21.2953C25.293 20.9336 26.098 19.8953 26.098 18.6703V16.337C26.098 14.8086 24.8497 13.5603 23.3213 13.5603C21.793 13.5603 20.5447 14.8086 20.5447 16.337V18.6703C20.5447 19.8836 21.338 20.9103 22.423 21.2836V24.7953H2.33301C1.85467 24.7953 1.45801 25.192 1.45801 25.6703C1.45801 26.1486 1.85467 26.5453 2.33301 26.5453H23.2513C23.2747 26.5453 23.2863 26.557 23.3097 26.557C23.333 26.557 23.3447 26.5453 23.368 26.5453H25.6663C26.1447 26.5453 26.5413 26.1486 26.5413 25.6703C26.5413 25.192 26.1447 24.7953 25.6663 24.7953Z" fill="#707687"/>
<path id="Vector_3" d="M17.5117 17.2148H3.51168L3.5 15.4648H17.5L17.5117 17.2148Z" fill="#707687"/>
<g id="Group">
<path id="Vector_4" d="M10.5 26.5469C10.0217 26.5469 9.625 26.1502 9.625 25.6719V21.2969C9.625 20.8185 10.0217 20.4219 10.5 20.4219C10.9783 20.4219 11.375 20.8185 11.375 21.2969V25.6719C11.375 26.1502 10.9783 26.5469 10.5 26.5469Z" fill="#707687"/>
</g>
<path id="Vector_5" d="M10.5007 12.5482C11.6282 12.5482 12.5423 11.6341 12.5423 10.5065C12.5423 9.37893 11.6282 8.46484 10.5007 8.46484C9.37307 8.46484 8.45898 9.37893 8.45898 10.5065C8.45898 11.6341 9.37307 12.5482 10.5007 12.5482Z" fill="#707687"/>
</g>
</g>
</g>
</svg> 

  );
};
