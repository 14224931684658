import styled from "styled-components";

export const ModalDatacontainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  border-radius: 12px;
  background: #f2f3f7;
`;
export const ModalInformationData = styled.h5`
  all: unset;
  color: #171725;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 14px;
  font-style: normal;
  font-weight: 420;
  line-height: normal;
  letter-spacing: -0.1px;
`;
