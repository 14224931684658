export const loadingVerify = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
    >
      <path
        d="M24.3348 3.40356H14.3343C8.53153 3.40356 8.08398 8.61987 11.2168 11.4595L27.4522 26.2133C30.5851 29.053 30.1375 34.2693 24.3348 34.2693H14.3343C8.53153 34.2693 8.08398 29.053 11.2168 26.2133L27.4522 11.4595C30.5851 8.61987 30.1375 3.40356 24.3348 3.40356Z"
        stroke="#4266C8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
