import {
  IIFLUnavailableModalContainer,
  IconsContainer,
  TextTitle,
  TextDescription,
  TextContentContainer,
} from "./style";
import Icons from "../Icons";
import CTA from "@/components/CTA";
import { setShowModal, setUnavailableTryAgain, useStoreData } from "@/redux/onboardingSlice";
import { useDispatch } from "react-redux";
import { digioPreESignThunk,getElgibilityThunk } from "@/redux/onboardingThunks";

const IIFLUnavailableModal = () => {
  const {unavailableTryAgain} = useStoreData();
  const dispatch = useDispatch();
  const tryAgain = () => {
    switch(unavailableTryAgain){
      case 'digioPreESignThunk': {
        dispatch(setUnavailableTryAgain(null));
        dispatch(setShowModal({show: false, type: ""}))
        dispatch(digioPreESignThunk({}));
      }
      case 'elgibilityCheck': {
        dispatch(setUnavailableTryAgain(null));
        dispatch(setShowModal({show: false, type: ""}))
        dispatch(getElgibilityThunk({}));
      }

      
    }
  }
  return (
    <>
      <IIFLUnavailableModalContainer>
        <IconsContainer>
          <Icons name="redCloud" />
        </IconsContainer>
        <TextContentContainer>
          <TextTitle>
            Oops! Something
            <br />
            Went Wrong
          </TextTitle>

          <TextDescription>
            It seems there's a technical glitch, <br />
            Please try again
          </TextDescription>
        </TextContentContainer>
      </IIFLUnavailableModalContainer>
      <CTA
        customStyle={{
          gap: "4px",
          padding: "16px 16px",
          fontSize: "16px",
          fontWeight: "700",
          lineHeight: "20px",
          letterSpacing: "0.1px",
          borderRadius: "12px",
          height: "52px",
          width: "100%",
          color: "#fff",
        }}
        leftIcon={"reloadSymbol"}
        title="Try Again"
        fixed={"bottom"}
        onClick = {() => tryAgain()}
      />
    </>
  );
};

export default IIFLUnavailableModal;
