import { mixpanelEvents } from "@/constant/mixpanelEvents";

export const proofMapping = (proofCode: string | null, type: string | null) => {
  switch (proofCode) {
    case "2":
      return {
        name: "PAN CARD",
        submittingEvent: mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_PAN,
        successEvent: mixpanelEvents.MIXPANEL_VIEW_PAN_SUBMISSION_CONFIRMATION,
      };
    case "65":
      return {
        name: "PHOTO PROOF",
        submittingEvent: mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_PHOTO_PROOF,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_PHOTO_SUBMISSION_CONFIRMATION,
      };
    case "22":
      if(type == 'income-proof'){
        return {
            name: "BANK STATEMENT",
            submittingEvent:
              mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
            successEvent:
              mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
          };
      }else if(type == 'bank-details-proof'){
        return {
            name: "BANK STATEMENT",
            submittingEvent:
              mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_BANK_PROOF,
            successEvent:
              mixpanelEvents.MIXPANEL_VIEW_BANK_PROOF_SUBMISSION_CONFIRMATION,
          };
      };
    case "17":
      return {
        name: "ITR ACKNOWLEDGEMENT",
        submittingEvent:
          mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
      };
    case "19":
      return {
        name: "FORM 16",
        submittingEvent:
          mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
      };
    case "21":
      return {
        name: "SALARY SLIP",
        submittingEvent:
          mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
      };
    case "23":
      return {
        name: "DEMAT ACCOUNT HOLDING STATEMENT",
        submittingEvent:
          mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
      };
    case "45":
      if(type == 'income-proof'){
        return {
            name: "CANCELLED CHEQUE",
            submittingEvent:
              mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_FINANCIAL_PROOF,
            successEvent:
              mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_PROOF_SUBMISSION_CONFIRMATION,
          };
      }else if(type == 'bank-details-proof'){
        return {
            name: "CANCELLED CHEQUE",
            submittingEvent:
              mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_BANK_PROOF,
            successEvent:
              mixpanelEvents.MIXPANEL_VIEW_BANK_PROOF_SUBMISSION_CONFIRMATION,
          };
      }
      
    case "24":
      return {
        name: "SIGNATURE",
        submittingEvent: mixpanelEvents.MIXPANEL_VIEW_SUBMITTING_SIGNATURE,
        successEvent:
          mixpanelEvents.MIXPANEL_VIEW_FINANCIAL_SIGNATURE_CONFIRMATION,
      };

    default:
      return { name: "", submittingEvent: "", successEvent: "" };
  }
};
