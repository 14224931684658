import {
  HeaderContainer,
  HeaderSearch,
  Left,
  Padding,
  ProgressBar,
  Right,
  SearchHeaderContainer,
} from "./style";
import Icons from "../Icons/index";
import CTA from "../CTA";
import { useCallback, useEffect, useRef, useState } from "react";
import { backArrow } from "../Icons/files/backArrow";
import _ from "lodash";
import { debounce } from "debounce";
import { PAGES } from "../../constant/misc";

import {
  searchBankName,
  setBranchList,
  setDisplaySearch,
  setEligibility,
  setIsPreviewingDocument,
  setOtpSent,
  setShowEligibility,
  useStoreData,
} from "@/redux/onboardingSlice";

import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { fetchBranchThunk } from "@/redux/onboardingThunks";
import { useRouter } from "next/router";
import { usePathname } from "next/navigation";
import { closeEvent } from "@/util/closeEvent";
import { pageMapping } from "@/constant/pageMapping";
import { supportHandler } from "@/util/supportHandler";
import { isDesktop } from "react-device-detect";

const Progress = ({ stage, currentPage }) => {
  const [width, setWidth] = useState("0px");
  useEffect(() => {
    let width = (stage / 9) * window.innerWidth;
    setWidth(width + "px");
  }, [stage, currentPage]);
  return (
    <ProgressBar>
      <div
        className="progress"
        style={{
          height: "2px",
          width: `${width}`,
          backgroundColor: "#4266C8",
        }}
      ></div>
    </ProgressBar>
  );
};

const BankSearchHeader = (props) => {
  const [ifscValue, setIfscValue] = useState("");
  const [bankValue, setBankValue] = useState("");

  useEffect(() => {
    if (ifscValue.length !== 0) {
      debounceFunc(ifscValue);
    } else {
      dispatch(setBranchList([]));
    }
  }, [ifscValue]);
  const debounceFunc = useCallback(
    debounce((e) => dispatch(fetchBranchThunk({ searchParam: e })), 400),
    []
  );

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef?.current.focus();
  }, []);
  return (
    <SearchHeaderContainer>
      <span
        className="icon"
        onClick={() => {
          dispatch(setDisplaySearch({ bool: false, type: "" }));
        }}
      >
        <Icons name="backArrow" />
      </span>
      <HeaderSearch>
        <span className="icon">
          <Icons name="search" />
        </span>
        {props.searchType == "bank" ? (
          <input
            onChange={(e) => {
              let value = e.target.value.toUpperCase();
              setBankValue(value);
              dispatch(searchBankName(value));
            }}
            ref={inputRef}
            type="text"
            placeholder={"Search for Bank"}
            value={bankValue}
          />
        ) : (
          <input
            onChange={(e) => {
              let value = e.target.value.toUpperCase();
              setIfscValue(value);
            }}
            ref={inputRef}
            type="text"
            placeholder={"Enter branch name or IFSC"}
            value={ifscValue}
          />
        )}

        <span
          className="icon"
          onClick={() => {
            if (props.searchType == "bank") {
              setBankValue("");
              dispatch(searchBankName(""));
            } else {
              setIfscValue("");
            }
          }}
        >
          <Icons name="closeSearch" />
        </span>
      </HeaderSearch>
    </SearchHeaderContainer>
  );
};

const OpenIIFLHeader = (props) => {
  const { backType, getTitle, checkCTA, getBackAction, isDesktop } = props;
  if(isDesktop){
    return (
      <>
        <Left>
        {backType ? <span className="leftIcon" onClick={() => getBackAction()}>
            <Icons size={'36'} name={backType} />
          </span> : <div style={{width: '36px', height: '36px'}}></div>}
          <span className="leftIcon" onClick={() => getBackAction()}>
            <Icons name={'marketfeedLogoDesktop'} />
          </span>
        </Left>
        <Right>{checkCTA()}</Right>
      </>
    );
  }else{
    return (
      <>
        <Left>
          {backType && <span className="leftIcon" onClick={() => getBackAction()}>
            <Icons name={backType} />
          </span>}
          <span className="title">{getTitle()}</span>
        </Left>
        <Right>{checkCTA()}</Right>
      </>
    );
  }
  
};

const Header = (props) => {
  const {
    displaySearch,
    searchType,
    currentPage,
    otpSent,
    isPreviewingDocument,
    eligibility,
  } = useStoreData();
  const pathname = usePathname();
  const [backType, setBackType] = useState("close");
  const dispatch = useDispatch();
  const router = useRouter();
  useEffect(() => {
    switch (currentPage) {
      case PAGES.PERSONAL_ADDRESS_ADDRESS_PROOF:
      case PAGES.PERSONAL_ADDRESS_ADD_ADDRESS:
      case PAGES.BANK_PROOF:
      case PAGES.UPLOAD_BANK_PROOF_BANK_STATEMENT:
      case PAGES.UPLOAD_BANK_PROOF_CANCELLED_CHEQUE:
      case PAGES.UPLOAD_BANK_PROOF:
      case PAGES.UPLOAD_PROOF_BANK_STATEMENT:
      case PAGES.UPLOAD_PROOF_FORM16:
      case PAGES.UPLOAD_PROOF_ITR_ACKNOWLEDGMENT:
      case PAGES.UPLOAD_PROOF_SALARY_SLIP:
      case PAGES.UPLOAD_PROOF:
      case PAGES.SIGN_ON_SCREEN:
      case PAGES.VERIFY:
        setBackType("backArrow");
        break;
      case PAGES.PERSONAL_DETAILS:
        setBackType("close");
        break;
      case PAGES.SIGNATURE_VERIFICATION_METHODS:
      case PAGES.UPLOAD_PROOF_V2_PAN_CARD:
      case PAGES.UPLOAD_PROOF_V2_SELFIE:
      case PAGES.UPLOAD_PROOF_V2: {
        if (isPreviewingDocument) setBackType("backArrow");
        else setBackType("close");
        break;
      }
      case PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY: {
        if(eligibility){
          setBackType("backArrow");
        }else setBackType(null);
        break;
      }
      default:
        setBackType("close");
    }
  }, [currentPage, isPreviewingDocument, eligibility]);

  const getBackAction = () => {
    switch (currentPage) {
      case PAGES.VERIFY: {
        if (otpSent) {
          dispatch(setOtpSent(false));
        } else {
          closeEvent();
        }
        break;
      }
      case PAGES.PERSONAL_DETAILS: {
        closeEvent();
        break;
      }
      case PAGES.PERSONAL_ADDRESS: {
        closeEvent();
        break;
      }
      case PAGES.PERSONAL_ADDRESS_ADD_ADDRESS: {
        router.push(PAGES.PERSONAL_ADDRESS);
        break;
      }
      case PAGES.BANK_DETAILS: {
        closeEvent();
        break;
      }
      case PAGES.BANK_PROOF: {
        router.push(PAGES.BANK_DETAILS);
        break;
      }
      case PAGES.UPLOAD_PROOF_V2_PAN_CARD:
      case PAGES.UPLOAD_PROOF_V2_SELFIE:
      case PAGES.UPLOAD_PROOF_V2: {
        if (isPreviewingDocument) dispatch(setIsPreviewingDocument(false));
        else closeEvent();
        break;
      }
      case PAGES.UPLOAD_BANK_PROOF:
      case PAGES.UPLOAD_BANK_PROOF_BANK_STATEMENT:
      case PAGES.UPLOAD_BANK_PROOF_CANCELLED_CHEQUE: {
        router.push(PAGES.BANK_PROOF);
        break;
      }
      case PAGES.FINANCIAL_PROOF: {
        closeEvent();
        break;
      }
      case PAGES.UPLOAD_PROOF:
      case PAGES.UPLOAD_PROOF_FORM16:
      case PAGES.UPLOAD_PROOF_ITR_ACKNOWLEDGMENT:
      case PAGES.UPLOAD_PROOF_SALARY_SLIP:
      case PAGES.UPLOAD_PROOF_BANK_STATEMENT: {
        router.push(PAGES.FINANCIAL_PROOF);
        break;
      }
      case PAGES.SEGMENT_ACTIVATION: {
        closeEvent();
        break;
      }
      case PAGES.SIGN_ON_SCREEN:
        router.push(PAGES.SIGNATURE_VERIFICATION_METHODS);
        break;
      case PAGES.SIGNATURE_VERIFICATION_METHODS: {
        if (isPreviewingDocument) {
          dispatch(setIsPreviewingDocument(false));
        } else {
          closeEvent();
        }
        break;
      }

      case PAGES.PERSONAL_ADDRESS_ADDRESS_PROOF: {
        if (isPreviewingDocument) {
          dispatch(setIsPreviewingDocument(false));
        } else {
          router.push(PAGES.PERSONAL_ADDRESS);
        }
        break;
      }
      case PAGES.ESIGN: {
        closeEvent();
        break;
      }
      case PAGES.ESIGN: {
        closeEvent();
        break;
      }
      case PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY: {
        switch (eligibility) {
          case "ELIGIBLE_USER":
          case "ACTIVATED":
          case "UNMAPPED":
          case "INELIGIBLE_USER":
          case "PENDING": {
            dispatch(setEligibility(null));
            dispatch(setShowEligibility(false));
          }
        }
        break;
      }
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS: {
        router.push(PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY);
        break;
      }
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI:
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO: {
        router.push(PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS);
        break;
      }
      case PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE: {
        router.push(PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY);
        break;
      }case PAGES.LINK_ACCOUNT_AUTHORISE_TRADES: {
        router.push(PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY);
        break;
      }
    }
  };

  const getTitle = () => {
    switch (currentPage) {
      case PAGES.LINK_ACCOUNT_CHECK_ELIGIBILITY:
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS: {
        return "Link Existing Account";
      }
      case PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE: {
        return "How to Resolve?";
      }
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI: {
        return "How to Activate DDPI?";
      }
      case PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO: {
        return "How to Activate FNO?";
      }
      case PAGES.LINK_ACCOUNT_AUTHORISE_TRADES: {
        return "Link Broker Account";
      }
      default:
        return "Open IIFL Account";
    }
  };

  const checkCTA = () => {
    switch (props.stage) {
      default:
        return (
          <CTA
            customStyle={isDesktop ? {
              gap: "4px",
              padding: "0px 24px",
              fontSize: "16px",
              fontWeight: "500",
              borderRadius: "8px",
              color: "#4266C8",
              height: "52px",
            } :{
              gap: "4px",
              padding: "0px 12px",
              fontSize: "12px",
              fontWeight: "500",
              borderRadius: "8px",
              color: "#4266C8",
              height: "32px",
            }}
            clickDependency={[currentPage]}
            ghost
            type="support"
            leftIcon={"supportWhatsapp"}
            title={"Support"}
            onClick={() => {
              supportHandler(currentPage);
            }}
          />
        );
    }
  };
  return (
    <HeaderContainer>
      <Padding>
        {displaySearch ? (
          <AnimatePresence>
            <motion.div
              style={{ width: "100%" }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ type: "spring", duration: 0.5, delay: 0.35 }}
            >
              <BankSearchHeader searchType={searchType} />
            </motion.div>
          </AnimatePresence>
        ) : (
          <OpenIIFLHeader
            backType={backType}
            checkCTA={checkCTA}
            getTitle={getTitle}
            getBackAction={getBackAction}
            isDesktop={props.isDesktop}
          />
        )}
      </Padding>
      {
        <Progress
          stage={pageMapping[currentPage]?.stage}
          currentPage={currentPage}
        />
      }
    </HeaderContainer>
  );
};

export default Header;
