export const handleIntermediatePage = (
    currentPage: string | null,
    proofCode: string | null
  ) => {
    switch (proofCode) {
      case "36":
      case "27":
      case "26":
        return "address-proof";
      case "17":
      case "19":
      case "21":
        return "income-proof";
      case "2":
        return "upload-pan";
      case "65":
        return "upload-selfie";
      case "24":
        return "signature-upload";
      case "45":
        return "bank-details-proof";
      case "22":
        return currentPage?.includes("bank-proof")
          ? "bank-details-proof"
          : "income-proof";
    }
    return null;
  };

