export const failedTick = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M40.4998 73.3333C58.8332 73.3333 73.8332 58.3333 73.8332 40C73.8332 21.6666 58.8332 6.66663 40.4998 6.66663C22.1665 6.66663 7.1665 21.6666 7.1665 40C7.1665 58.3333 22.1665 73.3333 40.4998 73.3333Z"
        fill="#FD4F4F"
      />
      <path
        d="M31.0664 49.4331L49.9331 30.5664"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.9331 49.4331L31.0664 30.5664"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
