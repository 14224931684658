export const search = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M8.75 15.625C12.547 15.625 15.625 12.547 15.625 8.75C15.625 4.95304 12.547 1.875 8.75 1.875C4.95304 1.875 1.875 4.95304 1.875 8.75C1.875 12.547 4.95304 15.625 8.75 15.625Z"
        stroke="#707687"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.125 18.125L13.75 13.75"
        stroke="#707687"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
