export const uploadFile = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        opacity="0.4"
        d="M27.3325 13.5867H23.4792C20.3192 13.5867 17.7459 11.0134 17.7459 7.85341V4.00008C17.7459 3.26675 17.1459 2.66675 16.4125 2.66675H10.7592C6.65252 2.66675 3.33252 5.33341 3.33252 10.0934V21.9067C3.33252 26.6667 6.65252 29.3334 10.7592 29.3334H21.2392C25.3459 29.3334 28.6659 26.6667 28.6659 21.9067V14.9201C28.6659 14.1867 28.0659 13.5867 27.3325 13.5867Z"
        fill="#4266C8"
      />
      <path
        d="M21.0666 2.94658C20.5199 2.39991 19.5732 2.77324 19.5732 3.53324V8.18658C19.5732 10.1332 21.2266 11.7466 23.2399 11.7466C24.5066 11.7599 26.2666 11.7599 27.7732 11.7599C28.5332 11.7599 28.9332 10.8666 28.3999 10.3332C26.4799 8.39991 23.0399 4.91991 21.0666 2.94658Z"
        fill="#4266C8"
      />
      <path
        d="M18 18.3333H10C9.45333 18.3333 9 17.8799 9 17.3333C9 16.7866 9.45333 16.3333 10 16.3333H18C18.5467 16.3333 19 16.7866 19 17.3333C19 17.8799 18.5467 18.3333 18 18.3333Z"
        fill="#4266C8"
      />
      <path
        d="M15.3333 23.6667H10C9.45333 23.6667 9 23.2134 9 22.6667C9 22.1201 9.45333 21.6667 10 21.6667H15.3333C15.88 21.6667 16.3333 22.1201 16.3333 22.6667C16.3333 23.2134 15.88 23.6667 15.3333 23.6667Z"
        fill="#4266C8"
      />
    </svg>
  );
};
