import { styled } from "styled-components";

export const ModalMask = styled.div`
  display: flex;
  height: 100dvh;
  width: 100vw;
  opacity: 0.16;
  background-color: #707687;
  z-index: 99;
  position: sticky;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const ModalAnimation = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  .fadeIn {
    @keyframes slideInFromDown {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: 0.5s ease-out 0s 1 slideInFromDown;
  };
  .fadeOut {
    @keyframes slideInFromUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }
    animation: 0.1s ease-in 0s 1 slideInFromUp;
    animation-direction: reverse;
  };
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 1000;
  height:${(props)=>props.fullHeight?"100dvh":null}
`;

export const ModalLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px;
  border-radius: 20px 20px 0 0;
  border-bottom: ${(props) =>
    props.label && "1px solid rgba(124, 127, 134, 0.20)"};
  .label {
    color: #171725;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Satoshi-Variable;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.1px;
  }
  .icon {
    width: 24px;
    display: flex;
    align-items: center;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  z-index: 1500;
`;
