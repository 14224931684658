import axios, { AxiosRequestConfig, Method } from "axios";

export const axiosRequest = async ({
  method,
  path,
  data,
  idToken,
  contentType,
}: {
  method: string;
  path: string;
  data?: any;
  idToken?: string;
  contentType?: string;
}) => {
  var config: AxiosRequestConfig = {
    method: method,
    url: `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/${path}`,
    headers: {
      token: idToken,
      "Content-Type": contentType ? contentType : "application/json",
    },
    data: data,
  };
  try {
    const response = await axios(config);
    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};

export const axiosRequestLMS = async ({
  method,
  path,
  data,
}: {
  method: string;
  path: string;
  data?: any;
}) => {
  const baseURL = process.env.NEXT_PUBLIC_BASEURL;
  const endpoint = `${baseURL}/lead-management/${path}`;

  const config = {
    method: method,
    url: endpoint,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    data: data,
  };

  try {
    const response = await axios(config);
    return response;
  } catch (error: any) {
    throw error.response.data;
  }
};
