export const uploadPhoto = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M4.92829 20.358H19.0712C19.7813 20.358 20.3569 19.7823 20.3569 19.0722V4.92939C20.3569 4.21931 19.7813 3.64368 19.0712 3.64368H4.92829C4.21821 3.64368 3.64258 4.21931 3.64258 4.92939V19.0722C3.64258 19.7823 4.21821 20.358 4.92829 20.358Z" stroke="#707687" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.25 20.359L14.6357 11.5133C14.7512 11.4171 14.8968 11.3644 15.0471 11.3644C15.1975 11.3644 15.3431 11.4171 15.4586 11.5133L20.3571 15.0233" stroke="#707687" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.78551 10.7151C9.85063 10.7151 10.7141 9.85161 10.7141 8.78648C10.7141 7.72136 9.85063 6.85791 8.78551 6.85791C7.72038 6.85791 6.85693 7.72136 6.85693 8.78648C6.85693 9.85161 7.72038 10.7151 8.78551 10.7151Z" stroke="#707687" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    );
  };
  
