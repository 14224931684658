import { styled } from "styled-components";

export const SubmitPageContainer = styled.div`
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 658px;
  padding: 16px 40px;
  width: 100%;
`;

export const IconContainer = styled.div`
  display: flex;
  width: ${(props) => (props.icon ? " 66.67px" : "80px")};
  height: ${(props) => (props.icon ? "66.67px" : "80px")};
  padding: ${(props) => (props.icon ? "14.817px" : "0px")};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 370.389px;
  @-webkit-keyframes rotating {
    from{
        -webkit-transform: rotate(0deg);
    }
    to{
        -webkit-transform: rotate(360deg);
    }
  } 
  -webkit-animation: rotating 2s linear infinite;
  background: ${(props) =>
    props.icon ? "rgba(129, 152, 218, 0.2)" : "rgba(129, 152, 218, 0.0)"};
`;
export const SubmitTitle = styled.h2`
  all: unset;
  color: #171725;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Satoshi-Variable;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
