import { supportWhatsapp } from "./supportWhatsapp";
import { backArrow } from "./backArrow";
import { close } from "./close";
import { calendar } from "./calendar";
import { verifiedTick } from "./verifiedTick";
import { notVerified } from "./notVerified";
import { modalClose } from "./modalClose";
import { checkingEmail } from "./checkingEmail";
import { refreshEmail } from "./refreshEmail";
import { preferred } from "./preferred";
import { loadingVerify } from "./loadingVerify";
import { successTick } from "./successTick";
import { failedTick } from "./failedTick";
import { search } from "./search";
import { closeSearch } from "./closeSearch";
import { upload } from "./upload";
import { listTick } from "./listTick";
import { branchIcon } from "./branchIcon";
import { clearSignature } from "./clearSignature";
import { fileUpload } from "./fileUpload";
import { takePhoto } from "./takePhoto";
import { uploadPhoto } from "./uploadPhoto";
import { successTickGhost } from "./successTickGhost";
import { checkBoxTick } from "./checkBoxTick.js";
import { digiLockerFail } from "./digiLockerFail.js";
import { frontArrow } from "./frontArrow.js";
import { digiLockerLogoCircle } from "./digiLockerLogoCircle.js";
import { digiLockerLogo } from "./digiLockerLogo.js";
import { loaderAnimated } from "./loaderAnimated";
import { toastFail } from "./toastFail";
import { toastCaution } from "./toastCaution";
import { toastSuccess } from "./toastSuccess";
import { infoIcon } from "./infoIcon";
import { uploadFile } from "./uploadFile";
import { closeFile } from "./closeFile";
import { deleteButton } from "./deleteButton";
import { downArrow } from "./downArrow";
import { redCloud } from "./redCloud";
import { reloadSymbol } from "./reload";
import { cautionBig } from "./cautionBig";
import { cancelGhost } from "./cancelGhost";
import { cancelBig } from "./cancelBig";
import { cancelGhostBig } from "./cancelGhostBig";
import { sandsOfTime } from "./sandsOfTime";
import { successTickBig } from "./successTickBig";
import { iiflLogoWords } from "./iiflLogoWords";
import { iiflLogo } from "./iiflLogo";
import {marketfeedLogoDesktop} from "./marketfeedLogoDesktop"

export default {
  supportWhatsapp,
  backArrow,
  close,
  calendar,
  verifiedTick,
  notVerified,
  refreshEmail,
  preferred,
  loadingVerify,
  successTick,
  failedTick,
  modalClose,
  checkingEmail,
  search,
  closeSearch,
  upload,
  listTick,
  branchIcon,
  clearSignature,
  fileUpload,
  takePhoto,
  uploadPhoto,
  successTickGhost,
  checkBoxTick,
  digiLockerFail,
  frontArrow,
  digiLockerLogoCircle,
  digiLockerLogo,
  loaderAnimated,
  toastFail,
  toastCaution,
  toastSuccess,
  infoIcon,
  uploadFile,
  closeFile,
  deleteButton,
  downArrow,
  redCloud,
  reloadSymbol,
  cautionBig,
  cancelGhost,
  cancelBig,
  cancelGhostBig,
  sandsOfTime,
  successTickBig,
  iiflLogo,
  marketfeedLogoDesktop,
  iiflLogoWords
};
