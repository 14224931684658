export const DIGILOCKER_SUCCESS = "success";
export const ESIGN_SUCCESS = "success";
export const DIGILOCKER_FAIL = "fail";
export const ESIGN_FAIl = "fail";


export const PAGES = {
  VERIFY: '/verify',
  PERSONAL_DETAILS: '/personal-details',
  PERSONAL_ADDRESS: '/personal-address',
  PERSONAL_ADDRESS_ADDRESS_PROOF: '/personal-address/address-proof',
  PERSONAL_ADDRESS_DIGILOCKER: '/personal-address/digilocker',
  PERSONAL_ADDRESS_ADD_ADDRESS: '/personal-address/add-address',
  UPLOAD_PROOF_V2: '/upload-proof-v2/[proofCode]',
  UPLOAD_PROOF_V2_PAN_CARD: '/upload-proof-v2/2',
  UPLOAD_PROOF_V2_SELFIE: '/upload-proof-v2/65',
  BANK_DETAILS: '/bank-details',
  BANK_PROOF: '/bank-proof',
  UPLOAD_BANK_PROOF: '/upload-bank-proof/[proofCode]',
  UPLOAD_BANK_PROOF_BANK_STATEMENT: '/upload-bank-proof/22',
  UPLOAD_BANK_PROOF_CANCELLED_CHEQUE: '/upload-bank-proof/45',
  SEGMENT_ACTIVATION: '/segment-activation',
  FINANCIAL_PROOF: '/financial-proof',
  UPLOAD_PROOF: '/upload-proof/[proofCode]',
  UPLOAD_PROOF_BANK_STATEMENT: '/upload-proof/22',
  UPLOAD_PROOF_ITR_ACKNOWLEDGMENT: '/upload-proof/17',
  UPLOAD_PROOF_FORM16: '/upload-proof/19',
  UPLOAD_PROOF_SALARY_SLIP: '/upload-proof/21',
  ESIGN: '/esign',
  ESIGN_STATUS: '/esign-status',
  SIGNATURE_VERIFICATION_METHODS: '/signature-verification-methods',
  SIGN_ON_SCREEN: '/sign-on-screen',
  LOADING: '/loading',
  LINK_ACCOUNT_CHECK_ELIGIBILITY: '/link',
  LINK_ACCOUNT_HOW_TO_RESOLVE: '/link/how-to-resolve',
  LINK_ACCOUNT_AUTHORISE_TRADES: '/link/authorise-trades',
  LINK_ACCOUNT_ACTIVATE_SEGMENTS: '/link/activate-segments',
  LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_FNO: '/link/activate-segments/activate-fno',
  LINK_ACCOUNT_ACTIVATE_SEGMENTS_ACTIVATE_DDPI:'/link/activate-segments/activate-ddpi',
  APP_LOADING: '/app-loading'
}


export const ownersData = [
  "Self",
  "Spouse",
  "Dependent Children",
  "Dependent Siblings",
  "Dependent Parents",
  "Guardian",
  "PMS",
  "Custodian",
  "POA",
];
