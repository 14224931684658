import { PAGES } from "./misc";

type eligibilityCheckT = {
  [index: string]: {
    icon: string,
    title: string,
    context: string,
    cta: Object,
    iifl: boolean,
    iconFront?:boolean,
    iconColor?:string,
    whatsNext?:boolean
  }
}

const eligibilitycheck: eligibilityCheckT = {
  ELIGIBLE_USER: {
    icon: "successTickBig",
    title: "Your IIFL Account is compatible for Automated Trades",
    context: "Please continue further for eligibility checks",
    cta: { cta1: { text: "Continue", icon: "frontArrow",link:PAGES.LINK_ACCOUNT_ACTIVATE_SEGMENTS }, cta2: null },
    iifl: true,
  },
  ACTIVATED: {
    icon: "cancelGhostBig",
    title: "Trade Automation is Already Live in this IIFL Account",
    context: "Please try with another account or contact support to continue",
    cta: {
      cta1: {
        text: "Try with Another Account",
        icon: "reloadSymbol",
      },
      cta2: { text: "Contact Support", icon: "reloadSymbol" },
    },
    iifl: true,
    iconFront: true,
  },
  UNMAPPED: {
    icon: "cancelGhostBig",
    title: "Your IIFL Account is Not Compatible for Trade Automation",
    context:
      "Please contact support to continue or you can try with a different IIFL account",
    cta: {
      cta1: { text: "Contact Support", icon: null },
      cta2: {
        text: "How to Resolve?",
        icon: null,
        link: PAGES.LINK_ACCOUNT_HOW_TO_RESOLVE,
      },
    },
    iifl: false,
  },
  INELIGIBLE_USER: {
    icon: "sandsOfTime",
    title: "Your IIFL account is either under processing or incompatible",
    context:
      `Typically, it takes 1 to 2 days to become active if you've recently opened it. We'll notify you once it's active. \n\n For further assistance, please contact supportor try using a different account`,
    cta: {
      cta1: { text: "Contact Support", icon: null },
      cta2: { text: "Try with Another Account", icon: "reloadSymbol" },
    },
    iconFront: true,
    iifl: false,
    iconColor: "#4266C8",
  },
  PENDING: {
    icon: "sandsOfTime",
    title: "Your IIFL Account Opening is Still in Progress..",
    context:
      "We will notify you once your IIFL account is active, usually it takes 1 to 2 days",
    cta: {
      cta1: { text: "Got it", icon: null },
      cta2: null,
    },
    iifl: false,
    whatsNext: true,
  },
};

export default eligibilitycheck;
